import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import styles from "../style.module.css";
import { changeStatus, getSpecifiedMaster } from "../api";
import { messages } from "../../../utils/messages";
import { MASTER } from "../../../utils/master";

const ChangeStatus = ({ showModal, handleClose }) => {
	const masterId = useSelector((state) => state.masters.id);
	const [loading, setLoading] = useState(true);
	const [currentMaster, setCurrentMaster]=useState()
	useEffect(() => {
	 setLoading(true);
	 if(masterId&&masterId!=="")
	 getSpecifiedMaster(masterId).then((res)=>{
				setCurrentMaster(res.data);
				setLoading(false)
	 })

	}, [showModal])
	

	const handleChangeStatus = (newStatus) => {
		toast.promise(changeStatus(newStatus, masterId), {
			pending: {
				render() {
					return messages.MASTER.CHANGE_STATUS;
				},
			},
			success: {
				render() {
					handleClose();

					return messages.MASTER.CHANGED_STATUS;
				},
			},
			error: {
				render() {
					return messages.MASTER.CHANGE_STATUS_FAILED;
				},
			},
		});
	};
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>Change Status</Modal.Title>
				</Modal.Header>
				<Form>
				<Modal.Body>
					{!loading?<>
						{currentMaster?.status!==MASTER.STATUS.PENDING&&<h5>Do you want to {currentMaster?.status===MASTER.STATUS.APPROVED?"Disable":"Enable"} it?</h5>}
						
						<div className={`justify-content-center gap-3 ${styles.spacebetween}`}>
								{currentMaster?.status===MASTER.STATUS.PENDING&&<Button
									onClick={()=>handleChangeStatus(MASTER.STATUS.APPROVED)}
									className={`${styles.statusTrue} mt-3`}
								>
                Approve
								</Button>}
								{currentMaster?.status===MASTER.STATUS.PENDING&&<Button
									onClick={()=>handleChangeStatus(MASTER.STATUS.REJECTED)}							
									className={`${styles.statusFalse} mt-3`}
								>
                Reject
								</Button>}
								{currentMaster?.status!==MASTER.STATUS.PENDING&&<Button
									onClick={()=>handleChangeStatus(currentMaster?.status===MASTER.STATUS.APPROVED?MASTER.STATUS.REJECTED:MASTER.STATUS.APPROVED)}
									className={`${styles.statusTrue} mt-3`}
								>
                Yes
								</Button>}
								{currentMaster?.status!==MASTER.STATUS.PENDING&&<Button
									onClick={handleClose}
								
									className={`${styles.statusFalse} mt-3`}
								>
                No
								</Button>}
							</div>
					
					</>:(
						<div className="d-flex justify-content-center">
							<div className="spinner-border" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					)}
				</Modal.Body>
				</Form>
			</div>
		</Modal>
	);
};

export default ChangeStatus;
