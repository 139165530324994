import { axios } from "../../../lib/axios"
import { ApiRoutes } from "../../../utils/api_Routes"
import { POSTS } from "../../../utils/posts"

export const getAllBusiness=(status, pagination, search, selectedKey, order)=>{
	const param=status===POSTS.STATUS.ALL?{...pagination}:{status, ...pagination}
	return axios.get(ApiRoutes.BUSINESS.GET_ALL, {params:{...param, ...({sortOrder:order}),...(search!==""&&{search})}})
}
export const changeStatus=(status, id)=>{

	return axios.put(`${ApiRoutes.BUSINESS.CHANGE_STATUS}/${status==="approved"?"approve":"reject"}/${id}`)

}
