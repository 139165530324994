import React, { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import commonstyles from "../../../common/common.module.css";


import styles from "../style.module.css";
import { addTooltip, getSpecificTooltip, updateTooltip } from "../api";
// import { USER } from "../../../utils/user";
import { messages } from "../../../utils/messages";
import { TooltipSchema } from "../../ToolTips/validations";
import { toolTips } from "../../../utils/tooltips";

const EditTooltip = ({ add, setAdd, showModal, handleClose, selectedKey }) => {
	const [loading, setLoading]=useState(true)


	useEffect(() => {
		if(!add)
		{
			setLoading(true);
			if(selectedKey)
			{	getSpecificTooltip(selectedKey).then((res)=>{
				userDetailsFormik.setValues(res.data)
			
				setLoading(false)		
			})   
			}
		}
		else{
			userDetailsFormik.resetForm()
			setLoading(false)
		}
	}, [showModal])
   
    
	const userDetailsFormik = useFormik({
		initialValues: {
			text: "",
			name:"",
			labelId:"",
			
			
		},
		validationSchema: add&&TooltipSchema,
		
		onSubmit: (values) => {
			setLoading(true)
			toast.promise(
				!add?
					updateTooltip(values.name, values.labelId, values.text, selectedKey):addTooltip(values.name, values.labelId, values.text),
				{
					pending: {
						render() {
							return messages.USER.EDIT
						},
					},
					success: {
						render() {
							userDetailsFormik.resetForm();
							handleClose();
								 setAdd(false);
	
							return messages.USER.EDITED
						},
					},
					error: {
						render() {
							return messages.USER.FAILED
						},
					},
				}
			);
		
		
		},
	});
	// const handleAudioUpload=(e)=>{
	// 	setLoading(true);
	// 	uploadAudioFiles(e.target.files[0]).then((res)=>{
	// 		userDetailsFormik.setFieldValue("audio", res.data.files)
	// 		setLoading(false)
	// 	})


	// }
	const handleEditUser=(e)=>{
		e.preventDefault();
		userDetailsFormik.submitForm();

	}
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
				setAdd(false)
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>
						{add?"Add":"Edit"} Tooltip
					</Modal.Title>
				</Modal.Header>
				{!loading?
						<Form onSubmit={handleEditUser}>
				<Modal.Body>
					
						
							{ add&&<><div className={styles.fieldStyle}>
								<Form.Group className="from-group" controlId="formFirstName">
									<Form.Label>Name</Form.Label>
									<Form.Control
										type="text"
										placeholder="Name"
										value={userDetailsFormik.values.name}
										name={toolTips.NAME}
										onChange={userDetailsFormik.handleChange}
									/>
								</Form.Group>
								{userDetailsFormik.errors.name &&
                                userDetailsFormik.touched.name ? (
										<span className={commonstyles.error}>
											{userDetailsFormik.errors.name}
										</span>
									) : null}
							</div>		
								
							<div className={styles.fieldStyle}>
								<Form.Group className="from-group" controlId="formFirstName">
									<Form.Label>Label Id</Form.Label>
									<Form.Control
										type="text"
										placeholder="label"
										value={userDetailsFormik.values.labelId}
										name={toolTips.LABELID}
										onChange={userDetailsFormik.handleChange}
									/>
								</Form.Group>
								{userDetailsFormik.errors.labelId &&
                                userDetailsFormik.touched.labelId ? (
										<span className={commonstyles.error}>
											{userDetailsFormik.errors.labelId}
										</span>
									) : null}
							</div></>	}
							<div className={styles.fieldStyle}>
								<Form.Group className="from-group" controlId="formFirstName">
									<Form.Label>Text</Form.Label>
									<Form.Control
										type="text"
										placeholder="Name"
										value={userDetailsFormik.values.text}
										name={toolTips.TEXT}
										onChange={userDetailsFormik.handleChange}
									/>
								</Form.Group>
								{userDetailsFormik.errors.text &&
                                userDetailsFormik.touched.text ? (
										<span className={commonstyles.error}>
											{userDetailsFormik.errors.text}
										</span>
									) : null}
							</div>
									
							{/* <div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Verification Status</Form.Label>
								<Form.Control
									name={USER.FORMIK.ISVERIFIED}
									value={userDetailsFormik.values.isVerified}
									as="select"
									onChange={userDetailsFormik.handleChange}

									
								>
									<option value={true} key={1}>
                    Verify
									</option>
									<option value={false} key={2}>
                    Unverify
									</option>
								
								</Form.Control>
							</Form.Group>
							{userDetailsFormik.errors.isVerified &&
                                userDetailsFormik.touched.isVerified ? (
									<span className={commonstyles.error}>
										{userDetailsFormik.errors.isVerified}
									</span>
								) : null}
							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Status</Form.Label>
								<Form.Control
									name={USER.FORMIK.STATUS}
									value={userDetailsFormik.values.status}
									onChange={userDetailsFormik.handleChange}

									as="select"
									
								>
									<option value={true} key={1}>
                    Active
									</option>
									<option value={false} key={2}>
                    In Active
									</option>
								
								</Form.Control>
							</Form.Group>
							{userDetailsFormik.errors.status &&
                                userDetailsFormik.touched.status ? (
									<span className={commonstyles.error}>
										{userDetailsFormik.errors.status}
									</span>
								) : null}
						</div> */}
					

							{/* <Button variant="outline-secondary" className="mb-2">
                    <i className="fas fa-plus"></i> Choose More Department
                </Button> */}
							{/* <hr /> */}
							{/* <Button variant="outline-secondary" className="mb-2">
                    <i className="fas fa-plus"></i> Add More University
                </Button> */}
						
						
				</Modal.Body>
				<Modal.Footer>
					<Button className={`${styles.buttonss} mt-3`} type="submit">
						{add?"Add":"Edit"}
					</Button>
				</Modal.Footer>
				</Form>:(
					<div className="d-flex justify-content-center">
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				)}
			</div>
		</Modal>
	)
}

export default EditTooltip