import React, { useState } from "react"
import { Button, Form, Modal } from "react-bootstrap";
// import { Button, Form, Modal } from "react-bootstrap";
// import { useSelector } from "react-redux";
// import { toast } from "react-toastify";
import { toast } from "react-toastify";
// import { removeUser } from "../api";
// import { messages } from "../../../utils/messages";

import styles from "../style.module.css";
import { USER } from "../../../utils/user";
import { messages } from "../../../utils/messages";

const SelectColumns = ({ showModal, handleClose, selectedKeys, setSelectedKeys }) => {
	const [check, setChecked]=useState(selectedKeys)

	// const userId = useSelector((state) => state.users.id);
		

	const handleCheck=(e)=>{
		const { name, checked } = e.target;
		if (checked) {
			  // If checkbox is checked, add the key to the selectedKeys array
			  if(check.length<5)
			  {
			  setChecked(prevSelectedKeys => [...prevSelectedKeys, name]);
			  }
			  else{
			  toast.error(messages.USER.MAX_COLUMNS)
			  }
		} else {
			  // If checkbox is unchecked, remove the key from the selectedKeys array
			  if(check.length>2)
			  {
				setChecked(prevSelectedKeys => prevSelectedKeys.filter(key => key !== name));	
		  }
		  else{
				toast.error(messages.USER.MIN_COLUMNS)
		  }
			
		}
	}
	const handleSubmit=()=>{
		setSelectedKeys(check)
		handleClose()

	}

	
	// const handleChangeStatus=()=>{
	// 	toast.promise(
	// 		removeUser(userId),
	// 		{
	// 			pending: {
	// 				render() {
	// 					return messages.USER.REMOVE
	// 				},
	// 			},
	// 			success: {
	// 				render() {
	// 					handleClose();

	// 					return messages.USER.REMOVED
	// 				},
	// 			},
	// 			error: {
	// 				render() {
	// 					return messages.USER.FAILED
	// 				},
	// 			},
	// 		}
	// 	);
        
        
	// }
	return (

		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>Select Columns</Modal.Title>
				</Modal.Header>
				<Form >
					<Modal.Body>
						
						
							{USER.ALL_COLUMN_keys_ARRAY.map((key, id)=><div key={id} className="form-check">
								<input 
									onChange={handleCheck}
									checked={check.includes(key)}
									className="form-check-input"
									type="checkbox"
									id="option1"
									name={key}
									key={id}
									//   checked={checkboxes.option1}
									//   onChange={handleCheckboxChange}
								/>
								<label key={id} className="form-check-label" htmlFor="option1">
									{key}
								</label>
							</div>)}
							
							{/* <div className={styles.spacebetween}>
								<Button
									onClick={handleChangeStatus}
									className={`${styles.statusbuttons} mt-3`}
								>
					Yes
								</Button>
								<Button
									onClick={handleClose}
									className={`${styles.statusbuttons} mt-3`}
								>
					No
								</Button>
								
							</div> */}
						
						
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={handleSubmit}
							className={`${styles.statusbuttons} mt-3`}>
							Select
							</Button>
					</Modal.Footer>
				</Form>
			</div>
		</Modal>
	)
}

export default SelectColumns