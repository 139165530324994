import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faEye,
  faTableColumns,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import styles from "./style.module.css";
import commonStyles from "../../common/common.module.css";
import upload from "../../assets/images/upload.png";
import searchi from "./../../assets/images/search.png";
import { getAll, getExpenseReport } from "./api";
import { useInfiniteScroll } from "../../common/infiniteScroll/useInfiniteScroll";
import { Layout } from "../../common/Layout";
import { downloadExcel } from "../../common/api";
import { ApiRoutes } from "../../utils/api_Routes";
import { TIMESHEETS } from "../../utils/timesheets";
import { USER } from "../../utils/user";
import { RoutesUrl } from "../../utils/RoutesUrl";
import SelectColumns from "./modals/SelectTimesheetKeys";

function Expenserepoet() {
  // const dispatch = useDispatch();
  const [timesheets, setTimesheets] = useState([]);
  const [tab, setTab] = useState(TIMESHEETS.STATUS.ALL);
  const [timesheetsType, setTimesheetsType] = useState(TIMESHEETS.STATUS.ALL);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showChangeStatusModal, setChangeStatusModal] = useState(false);
  const [showSelectModal, setShowSelectModal] = useState(false);
  const [order, setOrder] = useState(-1);
  const [selectedKey, setSelectedKey] = useState([
    "workId",
    "userName",
    "businessName",
    "workNumber",
    "workType",
  ]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const elementRef = useRef(null);

  const handleExcelDownload = () => {
    const params = tab === TIMESHEETS.STATUS.ALL ? {} : { status: tab };
    downloadExcel(ApiRoutes.EXPENSE.excel, params);
  };

  const handleRemoveClose = () => {
    setShowRemoveModal(false);
    console.log(handleChangeStatusClose);
  };

  const handleChangeStatusClose = () => {
    setChangeStatusModal(false);
    console.log(handleRemoveClose);
  };

  const handleSelectClose = () => {
    setShowSelectModal(false);
  };

    const fetchMore = () => {
      setLoading(true);
      getAll({ page, limit: 10 }, selectedKey, search, order).then((res) => {
        setLoading(false);
        const { data } = res;
        if (data.length === 0) {
          setHasMore(false);
        } else {
          if (page === 1) {
            setTimesheets(data);
          } else {
            setTimesheets((prev) => [...prev, ...data]);
          }
          setPage((prev) => prev + 1);
        }

        setTimesheetsType(tab);
      });
    };

  // const fetchExpenseReport = async () => {
  //   setLoading(true);
  //   try {
  //     const res = await getExpenseReport(true);
  //     let fetchedData = res?.data;
  //     if (order === 1) {
  //       fetchedData = fetchedData.sort(
  //         (a, b) => new Date(a?.startDate) - new Date(b?.startDate)
  //       );
  //     } else if (order === -1) {
  //       fetchedData = fetchedData.sort(
  //         (a, b) => new Date(b?.startDate) - new Date(a?.startDate)
  //       );
  //     }
  //     setTimesheets(fetchedData);
  //     if (fetchedData.length === 0) {
  //       setHasMore(false);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching expense report data:", error);
  //   } finally {
  //     setLoading(false);
  //     setHasMore(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchExpenseReport();
  // }, []);

  // const filteredTimesheets = useMemo(() => {
  //   return timesheets.filter((item) => {
  //     return (
  //       item?.work?.workNumber.toString().includes(search) ||
  //       item?.startDate.toString().includes(search) || 
  //       item?.total.toString().includes(search) || 
  //       item?.createdAt.toString().includes(search) || 
  //       item?.expense?.approvedBy.toString().includes(search) || 
  //       item?.expense?.status.toString().includes(search) || 
  //       item?.expense?.approvedDate.toString().includes(search)
  //     );
  //   });
  // }, [timesheets, search]);

  // const sortedTimesheets = useMemo(() => {
  //   let sorted = filteredTimesheets;
  //   if (order === 1) {
  //     sorted = [...filteredTimesheets].sort(
  //       (a, b) => new Date(a?.startDate) - new Date(b?.startDate)
  //     );
  //   } else if (order === -1) {
  //     sorted = [...filteredTimesheets].sort(
  //       (a, b) => new Date(b?.startDate) - new Date(a?.startDate)
  //     );
  //   }
  //   return sorted;
  // }, [filteredTimesheets, order]);

  useInfiniteScroll(timesheets, elementRef, hasMore, fetchMore);

  useEffect(() => {
    setPage(1);
    setHasMore(true);
    setTimesheets([]);
  }, [tab]);

  useEffect(() => {
    if (showChangeStatusModal === false) {
      if (tab === timesheetsType) {
        setPage(1);
        setHasMore(true);
        setTimesheets([]);
      } else {
        setTab(timesheetsType);
      }
    }
  }, [showChangeStatusModal]);

  useEffect(() => {
    if (showRemoveModal === false) {
      if (tab === timesheetsType) {
        setPage(1);
        setHasMore(true);
        setTimesheets([]);
      } else {
        setTab(timesheetsType);
      }
    }
  }, [showRemoveModal]);

  useEffect(() => {
    if (showSelectModal === false) {
      setPage(1);
      setHasMore(true);
      setTimesheets([]);
    }
  }, [showSelectModal]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleOrder = (value) => {
    setOrder(Number(value));
    handleSearch();
  };

  const handleReset = () => {
    setSearch("");
    setPage(1);
    setHasMore(true);
    setTimesheets([]);
  };

  const handleSearch = () => {
    setPage(1);
    setHasMore(true);
    setTimesheets([]);
  };

  return (
    <Layout>
      <h2>Expense Report</h2>
      <div className={styles.tableTime}>
        <div className={styles.topTime}>
          <h5>
            Expense Reports <i className="fas fa-info-circle"></i>
          </h5>
          <div className={styles.topTimeInner}>
          <form className={styles.formGroup} 
								onSubmit={(e)=>{
									e.preventDefault();
									handleSearch()}
								}>
							
								<input value={search} onChange={handleSearchChange} disabled={loading}  type='text' placeholder='Search'/>
								<button className={styles.SaerchTag} onClick={handleSearch}  disabled={loading}><span><img src={searchi} alt=""/></span></button>
								<button disabled={loading} className={styles.resetButton} onClick={handleReset}  ><span>Reset</span></button>
							</form>
            {/* <form
              className={styles.formGroup}
              onSubmit={(e) => {
                e.preventDefault();
                handleSearch();
              }}
            >
              <input
                value={search}
                onChange={handleSearchChange}
                disabled={loading}
                type="text"
                placeholder="Search"
              />
              <button
                onClick={handleSearch}
                className={styles.SaerchTag}
                disabled={loading}
              >
                <span>
                  <img src={searchi} alt="" />
                </span>
              </button>
              <button
                disabled={loading}
                className={styles.resetButton}
                onClick={handleReset}
              >
                <span>Reset</span>
              </button>
            </form> */}
            <div className="ms-2">
            <button
              onClick={handleExcelDownload}
              className={styles.exportButton}
            >
              <img src={upload} alt="" /> Export
            </button>
            <button
              onClick={() => setShowSelectModal(true)}
              className={`ms-1 ${styles.selectButton}`}
            >
              <i className="me-1">
                <FontAwesomeIcon icon={faTableColumns} />
              </i>
              <span>Select Columns</span>
            </button>
            </div>
          </div>
        </div>
        <div className={`${styles.extrafilter} ${styles.topTime}`}>
          <div className={styles.FilterBtn}>
            <Form.Group className="from-group" controlId="type">
              <Form.Label>Order By Date:</Form.Label>
              <Form.Control
                value={order}
                as="select"
                onChange={(e) => handleOrder(e.target.value)}
              >
                <option value={1} key={1}>
                  Ascending
                </option>
                <option value={-1} key={2}>
                  Descending
                </option>
              </Form.Control>
            </Form.Group>
          </div>
        </div>
        <div className={styles.Tbalelayout}>
          <table>
            <tr>
              {selectedKey.map((key, id) => (
                <th key={id} className={styles.capitalize}>
                  {key}
                </th>
              ))}
              <th>Action</th>
            </tr>
            {timesheets?.map((user, id) => (
              <tr key={id}>
                {tab === USER.TYPE.ALL ? (
                  <>
                    {selectedKey.map((key, id) => {
                      if (key === USER.COLUMN_KEYS.STATUS) {
                        return (
                          <td key={id}>
                            <label className={!user.status && styles.inactive}>
                              {user.status ? "Active" : "In Active"}
                            </label>
                          </td>
                        );
                      } else if (key === TIMESHEETS.COLUMNS.USERNAME) {
                        return (
                          <>
                            <td><span className={styles.capitalize}>
                              {user[key]}
                            </span></td>
                          </>
                        );
                      } else if (key === TIMESHEETS.COLUMNS.WORKNUMBER) {
                        return <td>#{user[key]}</td>;
                      } else if (
                        key === TIMESHEETS.COLUMNS.WORK_UPDATED_AT ||
                        key === TIMESHEETS.COLUMNS.WORK_CREATED_AT ||
                        key === USER.COLUMN_KEYS.LAST_LOGIN ||
                        key === TIMESHEETS.COLUMNS.WORK_START_DATE ||
                        key === TIMESHEETS.COLUMNS.WORK_END_DATE
                      ) {
                        return (
                          <td key={id}>
                            {moment(user[key]).format("DD MMMM YYYY")}
                          </td>
                        );
                      } else {
                        if (key === TIMESHEETS.COLUMNS.WORK_TYPE) {
                          return (
                            <td key={id}>
                              {user[key].split("_").map((itemm) => {
                                return (
                                  itemm[0].toUpperCase() + itemm.slice(1) + " "
                                );
                              })}
                            </td>
                          );
                        } else {
                          return <td key={id}>{user[key]}</td>;
                        }
                      }
                    })}

                    <td>
                      <Dropdown className={commonStyles.tddropdown}>
                        <Dropdown.Toggle className={styles.dropdownIcon}>
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              navigate(`${RoutesUrl.EXPENSE_REPORT_ALL}`, {
                                state: {
                                  userId: user.userId,
                                  workId: user.workId,
                                },
                              });
                              setShowRemoveModal(true);
                            }}
                          >
                            <FontAwesomeIcon
                              size="xs"
                              className={commonStyles.iconmargin}
                              icon={faEye}
                            />
                            View More
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </>
                ) : (
                  <>
                    <td>
                      <img
                        className={styles.pp}
                        src={
                          tab === USER.TYPE.VIEWED
                            ? user?.userByViewed?.profilePicture
                            : user?.userByShared?.profilePicture
                        }
                      />
                      {tab === USER.TYPE.VIEWED
                        ? user?.userByViewed?.name
                        : user?.userByShared?.name}
                    </td>
                    <td>{user.count}</td>
                  </>
                )}
              </tr>
            ))}
          </table>
          {/* {!loading && (
            <table>
              <tr>
                <th className={styles.capitalize}>Week start Date</th>
                <th className={styles.capitalize}>Week End Date</th>
                <th className={styles.capitalize}>Total</th>
                <th className={styles.capitalize}>Created Date</th>
                <th className={styles.capitalize}>Approved By</th>
                <th className={styles.capitalize}>Approved Date</th>
                <th className={styles.capitalize}>Status</th>
                <th className={styles.capitalize}>Action</th>
              </tr>
              {sortedTimesheets?.map((data, ind) => {
                return (
                  <tr key={ind}>
                    <td className={styles.capitalize}>
                      {moment(data?.startDate).format("MM-DD-YYYY")}
                    </td>
                    <td className={styles.capitalize}>
                      {moment(data?.endDate).format("MM-DD-YYYY")}
                    </td>
                    <td className={styles.capitalize}>{data?.total}</td>
                    <td className={styles.capitalize}>
                      {moment(data?.createdAt).format("MM-DD-YYYY")}
                    </td>
                    <td className={styles.capitalize}>
                      {data?.expense?.approvedBy || "N/A"}
                    </td>
                    <td className={styles.capitalize}>
                      {data?.timesheet?.approvedDate ||
                      data?.expense?.approvedDate
                        ? (() => {
                            const date = new Date(
                              data?.timesheet?.approvedDate ||
                                data?.expense?.approvedDate
                            );
                            const month = String(date.getMonth() + 1).padStart(
                              2,
                              "0"
                            );
                            const day = String(date.getDate()).padStart(2, "0");
                            const year = date.getFullYear();
                            return `${month}-${day}-${year}`;
                          })()
                        : "Not Approved"}
                    </td>
                    <td className={styles.capitalize}>
                      {data?.expense?.status || "N/A"}
                    </td>
                    <td className={styles.capitalize}>
                      <Dropdown className={commonStyles.tddropdown}>
                        <Dropdown.Toggle className={styles.dropdownIcon}>
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                          onClick={() => {
                            navigate(`${RoutesUrl.VIEW_EXPENSE_DETAIL}/${data?.expense?._id}`);
                            setShowRemoveModal(true);
                          }}
                          >
                            <FontAwesomeIcon
                              size="xs"
                              className={commonStyles.iconmargin}
                              icon={faEye}
                            />
                            View More
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </table>
          )} */}
          {hasMore && (
            <div ref={elementRef}>
              <div
                className={`${styles.spinner} d-flex justify-content-center`}
              >
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <Remove
	  showModal={showRemoveModal}
	  handleClose={handleRemoveClose}
	  type={reviewType}
	/> */}
      <SelectColumns
        showModal={showSelectModal}
        handleClose={handleSelectClose}
        selectedKeys={selectedKey}
        setSelectedKeys={setSelectedKey}
      />
    </Layout>
  );
}

export default Expenserepoet;
