import React from "react";
import { Button, Form, Modal } from "react-bootstrap";


import styles from "../style.module.css";


const ChangeStatus = ({ showModal, handleClose, update }) => {

	const handleChangeStatus = () => {
		update();
		handleClose();
	};
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					{/* <Modal.Title>Are You Sure you want to update document</Modal.Title> */}
				</Modal.Header>
				<Form>
				<Modal.Body>
					<>
						{<h5 className="text-center">Are You Sure you want to update document ?</h5>}
						
						{/* <div className={`justify-content-center gap-3 ${styles.spacebetween}`}>
							
							</div> */}
				
					</>
				</Modal.Body>
				<Modal.Footer>
				<Button
					onClick={()=>handleChangeStatus()}
					className={`${styles.statusTrue} mt-3`}
				>
					Yes
				</Button>
				<Button
					onClick={()=>handleClose()}							
					className={`${styles.statusFalse} mt-3`}
				>
					No
				</Button>
								
				</Modal.Footer>
				</Form>
			</div>
		</Modal>
	);
};

export default ChangeStatus;
