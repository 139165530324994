import React, { useEffect, useRef, useState } from "react"
import {  useLocation, useNavigate} from "react-router-dom";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faEllipsisH, faEye, faTableColumns } from "@fortawesome/free-solid-svg-icons";

import commonStyles from "../../../common/common.module.css";
import styles from "./style.module.css";
import { allTimesheet} from "../api";
import { TIMESHEETS } from "../../../utils/timesheets";
import searchi from "../../../assets/images/search.png"
import { useInfiniteScroll } from "../../../common/infiniteScroll/useInfiniteScroll";
import { Layout } from "../../../common/Layout";
import upload from "../../../assets/images/upload.png"
import { USER } from "../../../utils/user";
import { RoutesUrl } from "../../../utils/RoutesUrl";
import { ApiRoutes } from "../../../utils/api_Routes";
import { downloadExcel } from "../../../common/api";
import SelectColumns from "../modals/SelectWorkTimesheetKeys";


function ViewMoreTimesheet() {
	const location = useLocation();


	// const date=Date.now()
	// console.log(date.toString())


	// useEffect(() => {
	// 	allTimesheet(location.state.userId,location.state.workId)
	// }, [])
	const [timesheets, setTimesheets] = useState([]);
	const [tab, setTab] = useState(TIMESHEETS.STATUS.ALL);
	const [activeTab, setActiveTab] = useState(TIMESHEETS.STATUS.ALL);
	const [timesheetsType, setTimesheetsType] = useState(TIMESHEETS.STATUS.ALL);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [showChangeStatusModal, setChangeStatusModal] = useState(false);
	const [hasMore, setHasMore]=useState(true);
	const [showSelectModal, setShowSelectModal] = useState(false);
	const [selectedKey, setSelectedKey]=useState(TIMESHEETS.WORK_DEFAULT_SELECTED_COLUMNS)
	const handleSelectClose=()=>{
		setShowSelectModal(false)
	}
	  const [page, setPage]=useState(1);
	const [loading, setLoading]=useState(true);
	const [search, setSearch]=useState("");
	const navigate=useNavigate();
  
	  const elementRef = useRef(null);
  
	const handleExcelDownload = () => {
		const params=tab===TIMESHEETS.STATUS.ALL?{}:{status:tab};
		params.userId=location.state.userId;
		params.workId=location.state.workId;
		downloadExcel(ApiRoutes.TIMESHEETS.WORK_EXCEL, params )
	}
  
   
	const handleRemoveClose = () => {
	  setShowRemoveModal(false);
	  console.log(handleChangeStatusClose)
	
	};
	const handleChangeStatusClose = () => {
	  setChangeStatusModal(false);
	  console.log(handleRemoveClose)
	};
  
	
	  const fetchMore=()=>{
	  setLoading(true)
	  allTimesheet(tab, location.state.userId, location.state.workId, {page, limit:10}, search).then((res)=>{
			setLoading(false)
			  const {data}=res;
			  if(data.length===0)
			  {
				  setHasMore(false)
			  }
			  else{
		  if(page===1){
					setTimesheets(data);		
		  }
		  else{
					setTimesheets(prev=>[...prev, ...data])			
			
		  }
		  setPage(prev=>prev+1)
			  }
			  setActiveTab(tab)
			setTimesheetsType(tab)
		  })
	 
	  }
  
	  useInfiniteScroll(timesheets, elementRef, hasMore, fetchMore);
  
	  useEffect(() => {
		  setPage(1);
		  setHasMore(true);
  
		  setTimesheets([])
	  }, [tab])
  
  
	useEffect(() => {
	  if (showChangeStatusModal === false) {
			if (tab === timesheetsType) {
		  setPage(1);
			  setHasMore(true);
  
			  setTimesheets([])
			} else {
		  setTab(timesheetsType);
			}
	  }
	}, [showChangeStatusModal]);
   
	useEffect(() => {
	  if (showRemoveModal === false) {
			if (tab === timesheetsType) {
		  setPage(1);
			  setHasMore(true);
  
			  setTimesheets([])
			} else {
		  setTab(timesheetsType);
			}
	  }
	}, [showRemoveModal]);
	useEffect(() => {
		if (showSelectModal === false) {
			  if (tab === timesheetsType) {
				setPage(1);
				setHasMore(true);
	
				setTimesheets([])
			  } else {
				setTab(timesheetsType);
			  }
		}
	  }, [showSelectModal]);
  
	const handleActivePanel = (type) => {
	  setTab(type);
	};
	const handleSearchChange=(e)=>{
	  setSearch(e.target.value)
	}
	const handleReset=()=>{
	  setSearch("")
	  setPage(1);
		  setHasMore(true);
  
		  setTimesheets([])
  
  
	}
	const handleSearch=()=>{
  
	  setPage(1);
		  setHasMore(true);
  
		  setTimesheets([])
	}

	return (
	// 
		<Layout>
			<h2>Timesheets</h2>
			{/* <div className={styles.OuterTime}>
		 
			</div> */}
			<div className={styles.tableTime}>
		  <div className={styles.topTime}>
					<h5>
			  Timesheets <i className="fas fa-info-circle"></i>
					</h5>
					<div className={styles.topTimeInner}>
			  <form className={styles.formGroup} 
							onSubmit={(e)=>{
								e.preventDefault();
								handleSearch()}
			  }>
							<input value={search} onChange={handleSearchChange} disabled={loading}  type='text' placeholder='Search'/>
							<button onClick={handleSearch} className={styles.SaerchTag} disabled={loading}><span><img src={searchi} alt=""/></span></button>
							<button disabled={loading} className={styles.resetButton} onClick={handleReset}  ><span>Reset</span></button>

			  </form>
						<button onClick={handleExcelDownload} className={styles.exportButton}>
							<i className="me-2"><img src={upload} alt="" /></i> Export
			  </button>
			  <button onClick={()=>setShowSelectModal(true)} className={`ms-1 ${styles.selectButton}`}>
			  <i className="me-2"><FontAwesomeIcon
								// className={commonStyles.iconmargin}
								icon={faTableColumns}
							/></i>
							<span>Select Columns</span>
						</button>
					</div>
					{/* <div className={styles.filters}>
			  <div className={styles.Formgroup}>
				<label>Arrange : </label>
				<select>
				  <option>Columns</option>
				  <option>Columns</option>
				</select>
			  </div>
			  <div className={styles.Formgroup}>
				<label>Select : </label>
				<select>
				  <option>Columns</option>
				  <option>Columns</option>
				</select>
			  </div>
			</div> */}
					{/* <button>
			  <img src={upload} alt="" /> Import
			</button> */}
					{/* <button>
			  <img src={upload} alt="" /> Excel Format
			</button> */}
					{/* <button
			  onClick={() => setShowAddTypeModal(true)}
			  disabled={loading}
			>
			  <img src={upload} alt="" /> Add{" "}
			  {masterType === "softwarelicences"
				? "Software Licences"
				: masterType.charAt(0).toUpperCase() + masterType.slice(1)}
			</button> */}
		  </div>
		  <div className={styles.Tabstable}>
					<ul>
			  <li
							className={
				  activeTab === TIMESHEETS.STATUS.ALL && styles.active
							}
			  >
							<p
				  onClick={() => handleActivePanel(TIMESHEETS.STATUS.ALL)}
							>
				  All
							</p>
			  </li>
			  <li
							className={
				  activeTab === TIMESHEETS.STATUS.APPROVED && styles.active
							}
			  >
							<p
				  onClick={() => handleActivePanel(TIMESHEETS.STATUS.APPROVED)}
							>
				 Approved
							</p>
			  </li>
			  <li
							className={
				  activeTab === TIMESHEETS.STATUS.PENDING&& styles.active
							}
			  >
							<p
				  onClick={() => handleActivePanel(TIMESHEETS.STATUS.PENDING)}
							>
				  Pending
							</p>
			  </li>
			  <li
							className={
				  activeTab === TIMESHEETS.STATUS.REJECTED && styles.active
							}
			  >
							<p
				  onClick={() =>
									handleActivePanel(TIMESHEETS.STATUS.REJECTED)
				  }
							>
				  Rejected
							</p>
			  </li>
					</ul>
		  </div>
				<div className={styles.Tbalelayout}>
			  <table>
			  <tr>
							{ selectedKey.map((key, id)=>(
								
								<th key={id} className={styles.capitalize}>{key}</th>
							
						
							))}
				  <th>Action</th>
						</tr>
						{timesheets?.map((user, id) => (
							<tr key={id}>
								
									 
								<>{selectedKey.map((key, id)=>{
									if(key===USER.COLUMN_KEYS.STATUS)
									{
										return <td>{user.status}</td>	
									}
									if(key===USER.COLUMN_KEYS.LANGUAGES)
									{
										if(user?.languages?.length)
										{
											const languageProficienciesString = user.languages[0]; 


											const languageProficienciesArray = languageProficienciesString.split(";")
											const commaSeparatedString = languageProficienciesArray.join(", ");
											return  commaSeparatedString


										}
										else{
											return "No Language Specified"
										}
									}
									else if(key===USER.COLUMN_KEYS.NAME)
									{
										return <td><img className={styles.pp} src={USER.DEFAULT_PROFILE_PICTURE} alt="" /><span className={styles.capitalize}>{user[key]}</span></td>
									}
									else if(key===USER.COLUMN_KEYS.IS_AVAILABLE)
									{
										return <td key={id}>
											<label className={!user.isAvailable && styles.inactive}>
												{user.isAvailable ? "Available" : "Not Available"}
											</label>
													
										</td>
									}
									else if(key===USER.COLUMN_KEYS.IS_VERIFIED)
									{
										return <td key={id} className="text-center">{user.isVerified ? "Verified" : "Not Verified"}</td>
									}
									else if(key===USER.COLUMN_KEYS.WORK_STATUS)
									{
										return <td key={id}>
											<label className={!user.workStatus && styles.inactive}>
												{user.workStatus ? "Available" : "Not Available"}
											</label>
													
										</td>
									}
									else if(key===USER.COLUMN_KEYS.CREATED_AT||key===USER.COLUMN_KEYS.UPDATED_AT||key===USER.COLUMN_KEYS.LAST_LOGIN||key===TIMESHEETS.COLUMNS.START_DATE||key===TIMESHEETS.COLUMNS.END_DATE||key===TIMESHEETS.COLUMNS.APPROVED_DATE)
									{
										return <td key={id}>{moment(user[key]).format("DD MMMM YYYY")}</td>

									}
									else{
										if(key!==USER.COLUMN_KEYS.EMAIL&&key!==USER.COLUMN_KEYS.LINKEDIN_LINK)
										{
											return <td key={id} className={styles.capitalize}>{user[key]}</td>
										}
										if(key===USER.COLUMN_KEYS.LINKEDIN_LINK)
										{
											return <td key={id} ><a href={user[key]}>{user[key]}</a></td>
										}
										
										return <td key={id}>{user[key]}</td>
									}
								})}
								
								<td>
									<Dropdown className={commonStyles.tddropdown}>
										<Dropdown.Toggle className={styles.dropdownIcon}>
											{/* <a className="me-2"></a> */}
											<FontAwesomeIcon
												// className={commonStyles.iconmargin}
												icon={faEllipsisH}
											/>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											

											<Dropdown.Item
												onClick={() => {
													navigate(`${RoutesUrl.TIMESHEETS}/${user._id}`);													setShowRemoveModal(true);
												}}
											>
												<FontAwesomeIcon
													size="xs"
													className={commonStyles.iconmargin}
													icon={faEye}
												/>
                               View More
											</Dropdown.Item>
												
										</Dropdown.Menu>
									</Dropdown>
								</td></>
									
													
							</tr>
						))}	
				
				 
			  </table>
			  {hasMore&&<div ref={elementRef}><div className= {`${styles.spinner} d-flex justify-content-center`}>
							  <div className="spinner-border" role="status">
								  <span className="sr-only">Loading...</span>
							  </div>
						  </div></div>}
				</div>
			</div>
	
   
			{/* <Remove
	  showModal={showRemoveModal}
	  handleClose={handleRemoveClose}
	  type={reviewType}
	/>
	<ChangeStatus
	  showModal={showChangeStatusModal}
	  handleClose={handleChangeStatusClose}
	/> */}
			<SelectColumns showModal={showSelectModal}
				handleClose={handleSelectClose}
				selectedKeys={selectedKey}
				setSelectedKeys={setSelectedKey}
			/>
		</Layout>

	)
}
export default ViewMoreTimesheet