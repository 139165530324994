export const messages = {
	AUTH:{
		LOGIN:"Logging In",
		LOGGED:"Logged In Successfully",
		LOGINFAILED:"Login Failed",
		IMAGE_UPLOAD_FAILED: "Image Upload failed"
	},
	USER:{
		EDIT:"Editing",
		EDITED:"Edited",
		FAILED:"Failed",
		REMOVE:"Removing",
		REMOVED:"Removed",
		UPDATED: "Updated",
		UPDATING: "Updating",
		MAX_COLUMNS:"You can't select more than 5",
		MIN_COLUMNS:"Atleast 2 columns needed"
	},
	MASTER:{
		ADDED: "Added",
		ADDING: "Adding",
		EDIT:"Editing",
		EDITED:"Edited",
		FAILED:"Failed",
		REMOVE:"Removing",
		REMOVED:"Removed",
		CHANGE_STATUS:"Changing Status",
		CHANGED_STATUS:"Status Changed Successfully",
		CHANGE_STATUS_FAILED:"Status Change Failed"
	},
	WEBSITE_INQUIRY: {
		FAILED:"Failed",
		REMOVE:"Removing",
		REMOVED:"Removed",
		REPLYING:"Replying",
		REPLIED:"Replied",
	},
	TEMPLATE:{
		SEND:"SENDING",
		SENT:"SENT",
	},
	BLOCK:{
		BLOCK:"Blocking User",
		BLOCKED:"User Blocked Successfully",
		BLOCK_FAILED:"Failed to block user"
	},
	REPORT:{
		REPORT:"Reporting User",
		REPORTED:"User reported Successfully",
		REPORT_FAILED:"Failed to report user"
	}

};
  