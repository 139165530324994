/* eslint-disable indent */
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import {  Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import searchi from "./../../assets/images/search.png"
import {
  faEllipsisH,
  faTrash,
  faCircleCheck,
  faStar,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";

import styles from "./style.module.css";
import upload from "../../assets/images/upload.png";
//import tabpro from "../../assets/images/tabpro.png";
// import cmlogo from "../../assets/images/cmlogo.png";
// import payone from "../../assets/images/payone.png"
// import paytwo from "../../assets/images/paytwo.png"
// import paythree from "../../assets/images/paythree.png"
//import info from "../../assets/images/info.png";
// import grarow from "../../assets/images/grarow.png";
// import blarow from "../../assets/images/blarow.png";
import commonStyles from "../../common/common.module.css";
import { getAll } from "./api";
import { REVIEW } from "../../utils/review";
import {Remove} from "./modals/Remove";
import ChangeStatus from "./modals/ChangeStatus";
import { editReviews } from "../../redux/slices/reviewSlice";
import {useInfiniteScroll} from "../../common/infiniteScroll/useInfiniteScroll";
import { Layout } from "../../common/Layout";
import { RoutesUrl } from "../../utils/RoutesUrl";
import {downloadExcel} from "../../common/api";
import { ApiRoutes } from "../../utils/api_Routes";

function Reviews() {
  const dispatch = useDispatch();
  const [review, setReview] = useState([]);
  const [tab, setTab] = useState(REVIEW.STATUS.ALL);
  const [activeTab, setActiveTab] = useState(REVIEW.STATUS.ALL);
  const [reviewType, setReviewType] = useState(REVIEW.STATUS.ALL);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showChangeStatusModal, setChangeStatusModal] = useState(false);
  const [hasMore, setHasMore]=useState(true);
	const [page, setPage]=useState(1);
  const [loading, setLoading]=useState(true);
  const [search, setSearch]=useState("");
  const navigate=useNavigate();

	const elementRef = useRef(null);

  const handleExcelDownload = () => {
    downloadExcel(ApiRoutes.REVIEW.excel, {search:search} )
  }

    const stars=(count)=>{
        const icons=[];
        for(var i=0; i<count; i++)
        {
            icons.push(  <FontAwesomeIcon
            size="xs"
            icon={faStar}
          />
            )
        }
        return icons
    }

 
  const handleRemoveClose = () => {
    setShowRemoveModal(false);
  };
  const handleChangeStatusClose = () => {
    setChangeStatusModal(false);
  };

  
	const fetchMore=()=>{
    setLoading(true)
		getAll(tab, {page, limit:10}, search).then((res)=>{
      setLoading(false)
			const {data}=res;
			if(data.length===0)
			{
				setHasMore(false)
			}
			else{
        if(page===1){
          setReview(data);
        }
        else{
          setReview(prev=>[...prev, ...data])
          
        }
        setPage(prev=>prev+1)
			}
			setActiveTab(tab)
      setReviewType(tab)
		})
   
	}

	useInfiniteScroll(review, elementRef, hasMore, fetchMore);

	useEffect(() => {
		setPage(1);
		setHasMore(true);

		setReview([])
	}, [tab])


  useEffect(() => {
    if (showChangeStatusModal === false) {
      if (tab === reviewType) {
        setPage(1);
			setHasMore(true);

			setReview([])
      } else {
        setTab(reviewType);
      }
    }
  }, [showChangeStatusModal]);
 
  useEffect(() => {
    if (showRemoveModal === false) {
      if (tab === reviewType) {
        setPage(1);
			setHasMore(true);

			setReview([])
      } else {
        setTab(reviewType);
      }
    }
  }, [showRemoveModal]);

  const handleActivePanel = (type) => {
    setTab(type);
  };
  const handleSearchChange=(e)=>{
    setSearch(e.target.value)
  }
  const handleReset=()=>{
    setSearch("")
    setPage(1);
		setHasMore(true);

		setReview([])


  }
  const handleSearch=()=>{

    setPage(1);
		setHasMore(true);

		setReview([])
  }

  return (
   <Layout>
          <h2>Reviews</h2>
          {/* <div className={styles.OuterTime}>
           
          </div> */}
          <div className={styles.tableTime}>
            <div className={styles.topTime}>
              <h5>
                Reviews <i className="fas fa-info-circle"></i>
              </h5>
              <div className={styles.topTimeInner}>
                <form className={styles.formGroup} 
                  onSubmit={(e)=>{
                  e.preventDefault();
                  handleSearch()}
                }>
                  <input value={search} onChange={handleSearchChange} disabled={loading}  type='text' placeholder='Search'/>
                  <button onClick={handleSearch} className={styles.SaerchTag} disabled={loading}><span><img src={searchi} alt=""/></span></button>
                  <button disabled={loading} className={styles.resetButton} onClick={handleReset}  ><span>Reset</span></button>

                </form>
              <button onClick={handleExcelDownload} className={styles.exportButton}>
                  <img src={upload} alt="" /> Export
                </button>
              </div>
              {/* <div className={styles.filters}>
                <div className={styles.Formgroup}>
                  <label>Arrange : </label>
                  <select>
                    <option>Columns</option>
                    <option>Columns</option>
                  </select>
                </div>
                <div className={styles.Formgroup}>
                  <label>Select : </label>
                  <select>
                    <option>Columns</option>
                    <option>Columns</option>
                  </select>
                </div>
              </div> */}
              {/* <button>
                <img src={upload} alt="" /> Import
              </button> */}
              {/* <button>
                <img src={upload} alt="" /> Excel Format
              </button> */}
              {/* <button
                onClick={() => setShowAddTypeModal(true)}
                disabled={loading}
              >
                <img src={upload} alt="" /> Add{" "}
                {masterType === "softwarelicences"
                  ? "Software Licences"
                  : masterType.charAt(0).toUpperCase() + masterType.slice(1)}
              </button> */}
            </div>
            <div className={styles.Tabstable}>
              <ul>
                <li
                  className={
                    activeTab === REVIEW.STATUS.ALL && styles.active
                  }
                >
                  <Link
                    onClick={() => handleActivePanel(REVIEW.STATUS.ALL)}
                  >
                    All
                  </Link>
                </li>
                <li
                  className={
                    activeTab === REVIEW.STATUS.APPROVED && styles.active
                  }
                >
                  <Link
                    onClick={() => handleActivePanel(REVIEW.STATUS.APPROVED)}
                  >
                   Approved
                  </Link>
                </li>
                <li
                  className={
                    activeTab === REVIEW.STATUS.PENDING&& styles.active
                  }
                >
                  <Link
                    onClick={() => handleActivePanel(REVIEW.STATUS.PENDING)}
                  >
                    Pending
                  </Link>
                </li>
                <li
                  className={
                    activeTab === REVIEW.STATUS.REJECTED && styles.active
                  }
                >
                  <Link
                    onClick={() =>
                      handleActivePanel(REVIEW.STATUS.REJECTED)
                    }
                  >
                    Rejected
                  </Link>
                </li>
              </ul>
            </div>
              <div className={styles.Tbalelayout}>
                <table>
                  <tr>
                    <th>Name </th>
                    <th>Reviewed By</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th className="text-center">Rating</th>


                    <th className="text-center">Status</th>
                    <th>Action</th>
                  </tr>
                  {review?.map((item, id) => (
                      <tr key={id}>
                        <td>
                          <img className={styles.pp} src={item.user.profilePicture} alt="" /> {item.user.name}
                        </td>
                        
                        <td>
                           {item.reviewBy.name}
                        </td>
                      
                        <td>
                          {moment(item.createdAt).format("MMM DD, YYYY")}
                        </td>
                        <td>
                          {moment(item.updatedAt).format("MMM DD, YYYY")}
                        </td>
                        <td className="text-center">
                          { 
                        stars(Number(item.rating)).map((val)=>val)
                         }
                        </td>
                        {/* <td><img src={cmlogo} alt="" /><b>Mosciski Inc.</b></td> */}
                        <td className="text-center">
                          <label className={item.status===REVIEW.STATUS.REJECTED?styles.rejected: item.status===REVIEW.STATUS.PENDING && styles.pending}>
                            {item.status[0].toUpperCase()+item.status.slice(1)}
                          </label>
                        </td>
                        <td>
                          <Dropdown className={commonStyles.tddropdown}>
                            <Dropdown.Toggle className={styles.dropdownIcon}>
                              {/* <a className="me-2"></a> */}
                              <FontAwesomeIcon
                                // className={commonStyles.iconmargin}
                                icon={faEllipsisH}
                              />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                            <Dropdown.Item
													onClick={() => {
														// dispatch(editUser({ id: user._id }));
														// setShowEditModal(true);
														navigate(`${RoutesUrl.REVIEW}/${item._id}`)
													}}
												>
													<FontAwesomeIcon
														size="xs"
														className={commonStyles.iconmargin}
														icon={faEye}
													/>
																View More
												</Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    dispatch(editReviews({ id: item._id}));
                                  setChangeStatusModal(true);
                                }}
                              >
                                <FontAwesomeIcon
                                  onClick={() => {}}
                                  size="xs"
                                  className={commonStyles.iconmargin}
                                  icon={faCircleCheck}
                                />
                                Change Status
                              </Dropdown.Item>

                              <Dropdown.Item
                                onClick={() => {
                                  dispatch(editReviews({ id: item._id}));
                                  setShowRemoveModal(true);
                                }}
                              >
                                <FontAwesomeIcon
                                  size="xs"
                                  className={commonStyles.iconmargin}
                                  icon={faTrash}
                                />
                                Remove
                              </Dropdown.Item>
                             
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                  }
                   
                </table>
                {hasMore&&<div ref={elementRef}><div className= {`${styles.spinner} d-flex justify-content-center`}>
								<div className="spinner-border" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							</div></div>}
              </div>
          </div>
      
     
      <Remove
        showModal={showRemoveModal}
        handleClose={handleRemoveClose}
        type={reviewType}
      />
      <ChangeStatus
        showModal={showChangeStatusModal}
        handleClose={handleChangeStatusClose}
      />
   </Layout>
  );
}

export {Reviews};
