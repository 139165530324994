import "bootstrap/dist/css/bootstrap.min.css";
// import { format } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import chatdot from "../../../assets/images/chatdot.png";
import chatone from "../../../assets/images/chatone.png";
import usr from '../../../assets/images/usr.png'
import moment from "moment";
import io, { Socket } from "socket.io-client";
import storage from "../../../utils/storage";
// / import BlockModal from "./blockModal";
// import ReportModal from "./reportModal";
import {
  format,
  isToday,
  isYesterday,
  differenceInDays,
  formatDistanceToNow,
} from "date-fns";
import { Layout } from "../../../common/Layout";
import { getUserProfileById } from "../api";
import { fetchUserChatLists } from "../api";
import styles from '../styles/style.module.css'
import BlockModal from "../modals/BlockModal";
import ReportModal from "../modals/ReportModal";
// import { getuserProfile } from "../../home/api";
// import { userProfileResponse } from "../../home/interface";
// const serverURL = process.env.REACT_APP_SERVER_URL as string;



function Chat() {
  const location = useLocation();
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);
  const [otherUserInfo, setOtherUserInfo] = useState(null);
  const [messages, setMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [userChatInfo, setUserChatInfo] = useState([]);
  const [messagess, setAllMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [filterType, setFilterType] = useState("all");
  const [chatUsers, setChatUsers] = useState([]);
  const scrollToBottomRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const serverURL = process.env.REACT_APP_CHAT_URL;

  const dropdownRef = useRef(null);
  const userId = location.pathname.split("/")[2] ?? null;
  useEffect(() => {
    const socketConnection = io(serverURL);
    setSocket(socketConnection);
    console.log(socketConnection,"connection")

    return () => {
      socketConnection.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!socket) return;

    socket.on("connect", () => {
      console.log("Connected to server");
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    socket.on("getChats", (data) => {
      const history = data.data;
      console.log("Chat history received:", data);
      setMessages(history);
      filterMessages(history);
      scrollToBottom();
    });

    socket.on("receiveAdminMessage", (newMessage) => {
      console.log("inside receive admin :::",)
      console.log("New message received:", newMessage);
      let newMessagecreatedAt = { createdAt: newMessage?.timestamp };
      let newMessageN = {
        ...newMessage,
        ...newMessagecreatedAt,
      };
      
      setAllMessages((prevMessages) => {
        if(prevMessages.length===0||newMessage.timestamp!==prevMessages[prevMessages.length-1].createdAt)

        { console.log("prevMessages", prevMessages.length);
        const updatedMessages = [...prevMessages, newMessageN];
        filterMessages(updatedMessages);

        // Increment unread messages count
        if (newMessage.status === "unread") {
          setUnreadMessagesCount((prevCount) => prevCount + 1);
        }

        if (userId) {
          // fetchUserData(userId);
          socket.emit("markAsSeen", {
            senderId: storage.getId(),
            receiverId: userId,
          });
        }
        return updatedMessages;}
        else{
          return prevMessages
        }
      });
      scrollToBottom();
    });

    socket.on("messageHistory", (history) => {
      console.log("Message history:", history);
      setMessages(history.reverse());
      filterMessages(history.reverse());
      scrollToBottom();
      setAllMessages(history);
    });
    socket.on("messagesSeen", ({ roomId, receiverId, isSeen }) => {
      console.log(`Messages seen for room ${roomId} by receiver ${receiverId}:`, isSeen);
      // Handle UI updates if necessary, e.g., marking messages as seen in the UI
    });
    return () => {
      socket.off("getChats");
      socket.off("receiveMessage");
      socket.off("messageHistory");
      socket.off("messagesSeen");
    };
  }, [socket, userId]);

  useEffect(() => {

    if (userId) {
      fetchUserData(userId);
      

      if (socket) {
        socket.emit("join", { senderId: storage.getId() });
        socket.emit("getAllChats", {
          senderId: storage.getId(),
          limit: 10,
          page: 1,
          type: "all",
        });
        socket.emit("joinChat", {
          senderId: storage.getId(),
          receiverId: userId,
        });
      }
    }

    fetchChatUsers();
  }, [location.pathname, socket]);

  useEffect(() => {
    // const fetchProfilePicture = async () => {
    //   try {
    //     // const response = await getuserProfile();
    //     console.log("response profile", response);

    //     // Ensure that data is not empty and profilePicture exists
    //     if (response.data?.profilePicture) {
    //       setProfilePicture(response.data.profilePicture);
    //     }
    //     console.log(
    //       "jgegegjkegfgfgrfsaasdasdasd",
    //       response.data.profilePicture
    //     );
    //   } catch (error) {
    //     console.error("Failed to fetch profile picture:", error);
    //   }
    // };

    // fetchProfilePicture();
  }, []);

  const fetchUserData = (userId) => {
    getUserProfileById(userId, "chats")
      .then((response) => {
        const userData = response.data;
        console.log("userData", userData);
        const user = {
          id: userData._id.toString(),  
          name: userData.name,
          profile_image: userData.profilePicture,
          status: userData?.status,
        };
        setOtherUserInfo(user);
        console.log("user", user);
        
        setIsBlocked(userData?.isBlocked);
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
      });

    console.log("isBlocked", isBlocked);
  };

  console.log("other user info", otherUserInfo);
  

  const filterMessages = (msgs) => {
    let filtered = [];
    switch (filterType) {
      case "unread":
        filtered = msgs.filter((msg) => msg.status === "unread");
        break;
      case "blocked":
        filtered = msgs.filter((msg) => msg.status === "blocked");
        break;
      case "reported":
        filtered = msgs.filter((msg) => msg.status === "reported");
        break;
      case "all":
      default:
        filtered = msgs;
    }

    setFilteredMessages(filtered);
  };

  const handleFilterChange = (
    filter
  ) => {
    setFilterType(filter);
    filterMessages(messages);
    fetchChatUsers(filter);
  };

  const handleMessageSend = () => {
    console.log("emit")
    if (message && socket && otherUserInfo) {
      socket.emit("sendMessage", {
        senderId: storage.getId(),
        receiverId: otherUserInfo.id,
        message: message,
        created_at: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        status: "read",
      });
      console.log({
        senderId: storage.getId(),
        receiverId: otherUserInfo.id,
        message: message,
        created_at: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        status: "read",
      })

      setMessage("");
      fetchChatUsers();
    }
  };



  const handleMessageSendOnEnter = (event) => {
    if (event.key === "Enter") {
      if (message && socket && otherUserInfo) {
        socket.emit("sendMessage", {
          senderId: storage.getId(),
          receiverId: otherUserInfo.id,
          message: message,
          created_at: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          status: "read",
        });
        setMessage("");
        fetchChatUsers();
      }
    }
  };

  const handleUserClick = (user) => {
    console.log(user);
    fetchUserData(user._id);
    navigate(`/chat/${user._id}`);
  };
  const loadMoreMessages = () => {
    setPage((prevPage) => prevPage + 1);
    if (socket) {
      socket.emit("getAllChats", {
        senderId: storage.getId(),
        limit: 10,
        page: page + 1,
        type: filterType,
      });
    }
  };

  const scrollToBottom = () => {
    if (messages?.length <= 10)
      scrollToBottomRef.current?.scrollIntoView({
        block: "end",
        inline: "nearest",
      });
  };

  function debounce(
    func,
    delay
  ){
    let timer;
    return (...args) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }
  

  const fetchChatUsers = useCallback(
    (filter = filterType, searchValue = "") => {
      console.log(
        "fetchChatUsers called with filter:",
        filter,
        "searchValue:",
        searchValue
      ); // Debugging line
      fetchUserChatLists(filter, searchValue)
        .then((users) => {
          setChatUsers(users); // Update state with fetched user list
        })
        .catch((error) => {
          console.error("Error fetching user chat list:", error);
        });
    },
    [filterType]
  );

  const debouncedFetchChatUsers = useCallback(debounce(fetchChatUsers, 300), [
    filterType,
  ]);

  const fetchChatUsersSearch = (event) => {
    let searchValue = event.target.value;
    console.log("fetchChatUsersSearch called with searchValue:", searchValue); // Debugging line
     debouncedFetchChatUsers(filterType, searchValue);
  };
  useEffect(() => {
    scrollToBottom();
  }, [filteredMessages]);

  const handleBlockSuccess = () => {
    setIsBlocked(!isBlocked); // Update blocked status on success
  };

  const toggleDropdown = () => {
    // setShowBlockModal(true);
    setShowDropdown(true);
  };

  const openBlockModal = () => {
    setShowBlockModal(true);

    setShowDropdown(false); // Close the dropdown when opening the modal
  };

  const openReportModal = () => {
    setShowReportModal(true);

    setShowDropdown(false); // Close the dropdown when opening the modal
  };
  const closeBlockModal = () => {
    setShowBlockModal(false);
  };
  const closeReportModal = () => {
    setShowReportModal(false);
  };
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target )
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  console.log("noti", chatUsers?.length);
  
  return (
    <>
    <Layout>
      <div className={styles.chatOuter}>
        <div className="container">
          <div className={styles.chatMain}>
            <div className={styles.chatLeft}>
              {/* <div className={styles.leftchathead}>
                <div>
                  <h3>
                    Messages <span>angle-down</span>
                  </h3>
                  <h4>16 Free Connects Available</h4>
                </div>
                <button>Buy Connects</button>
              </div> */}
              <div className={`p-3 ${styles.seahChat}`}>
                <input
                  type="search"
                  onChange={fetchChatUsersSearch}
                  placeholder="Search messages"
                //   onKeyUp={fetchChatUsersSearch}
                />
              </div>
              <div className={styles.userList}>
                <ul className={styles.memberList}>
                  <li
                    className={filterType === "all" ? styles.active : ""}
                    onClick={() => handleFilterChange("all")}
                  >
                    <span>All</span>
                  </li>
                  <li
                    className={filterType === "unread" ? styles.active : ""}
                    onClick={() => handleFilterChange("unread")}
                  >
                    <span>Unread</span>
                  </li>
                  <li
                    className={filterType === "blocked" ? styles.active : ""}
                    onClick={() => handleFilterChange("blocked")}
                  >
                    <span>Blocked</span>
                  </li>
                  <li
                    className={filterType === "reported" ? styles.active : ""}
                    onClick={() => handleFilterChange("reported")}
                  >
                    <span>Reported</span>
                  </li>
                </ul>

                <ul className={styles.userList}>
                  {chatUsers.length ? (
                    chatUsers.map((user) => (
                      <li
                        key={user.id}
                        className={styles.userListItem}
                        onClick={() => handleUserClick(user?.user)}
                      >
                        <span>
                          <img
                            height={48}
                            width={48}
                            className={styles?.objectFitContain}
                            src={
                              user?.user?.profilePicture &&
                                user?.user?.profilePicture.includes("https://")
                                ? user?.user?.profilePicture
                                : usr
                            }
                            alt={user.name}
                          />
                        </span>
                        <div>
                          <h5 className="text-capitalize clamp clamp1">
                            {user?.user?.name}
                            {/* <span>{formatDate(user?.user?.createdAt)}</span> */}
                          </h5>
                          <h6 className="clamp clamp2 fw-medium">{user?.lastMessage}</h6>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>No record found!</li>
                  )}
                </ul>
              </div>
            </div>
            <div className={styles.chatRight}>
              <div className={styles.chatLOgin}>
                <span>Admin is monitoring these messages</span>
              </div>
              {otherUserInfo && (
                <div className={styles.chatRightHead}>
                  <div className={styles.leftHead}>
                    <span>
                      <img
                        height={48}
                        width={48}
                        className={styles?.objectFitContain}
                        src={
                          otherUserInfo?.profile_image &&
                            otherUserInfo?.profile_image.includes("https://")
                            ? otherUserInfo?.profile_image
                            : usr
                        }
                        alt=""
                      />
                    </span>
                    <div>
                      <h5>{otherUserInfo.name}</h5>
                      <span>
                        {otherUserInfo?.status ? "Online" : "offline"}
                      </span>
                    </div>
                  </div>
                  <div
                    className={styles.chatDots}
                    onClick={toggleDropdown}
                    ref={dropdownRef}
                  >
                    <img src={chatdot} alt="" />
                    {showDropdown && (
                      <ul
                        className="dropdown-menu"
                        style={{ display: "block" }}
                      >
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={() => openBlockModal()}
                          >
                            {isBlocked ? "Unblock" : "Block"}
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={() => openReportModal()}
                          >
                            Report
                          </button>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              )}
              <div className={styles.pageChat}>
                {messagess.map((msg) => (
                 
                  
                  <div
                    key={msg.id}
                    className={`${styles.usrChat} ${msg.senderId === storage.getId()
                        ? styles.clientchta
                        : ""
                      }`}
                  >
                    {msg.senderId !== storage.getId() && (
                      <span>
                        <img
                          height={48}
                          width={48}
                          className={styles?.objectFitContain}
                          src={
                            otherUserInfo?.profile_image &&
                              otherUserInfo?.profile_image.includes("https://")
                              ? otherUserInfo?.profile_image
                              : usr
                          }
                          alt={otherUserInfo?.name}
                        />
                      </span>
                    )}
                    <div>
                      <label>{msg.message}</label>
                      <span style={{ fontSize: "10px" }}>
                        {formatDistanceToNow(new Date(msg.createdAt), {
                          addSuffix: true,
                        })}
                      </span>
                    </div>
                    {msg.senderId === storage.getId() && (
                      <span>
                        {profilePicture ? (
                          <img
                            src={profilePicture}
                            alt="Profile"
                            height={48}
                            width={48}
                          />
                        ) : (
                          <img src={usr} alt="" />
                        )}
                      </span>
                    )}
                  </div>
                ))}
                <div ref={scrollToBottomRef}></div>
              </div>
              {!isBlocked ? (
                <div className={styles.sentCaht}>
                <div className={`position-relative ${styles.cardfootertextarea}`}>
                  {/* <span>paperclip</span> */}
                  <div className={styles.formGroup}>
                    <textarea
                      placeholder="Type a message"
                      value={message}
                      className="form-control type_msg border-0 pe-5"
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyPress={handleMessageSendOnEnter}
                    >

                    </textarea>                
                      </div>
                  <label onClick={handleMessageSend}>Send</label>
                </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      {showBlockModal && (
        <BlockModal
          handleClose={closeBlockModal}
          userInfo={otherUserInfo}
          onBlockSuccess={handleBlockSuccess}
          // onUnblockSuccess={handleUnBlockSuccess}
          isBlocked={isBlocked}
        />
      )}
      {showReportModal && (
        <ReportModal handleClose={closeReportModal} userInfo={otherUserInfo} />
      )}
   </Layout> </>
  );
}

export default Chat;