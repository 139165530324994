import React from "react"
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import styles from "../style.module.css";
import { removeMaster } from "../api";
import { messages } from "../../../utils/messages";

const RemoveMaster = ({ showModal, handleClose }) => {
	const masterId = useSelector((state) => state.masters.id);

	const handleChangeStatus=()=>{
		toast.promise(
			removeMaster(masterId),
			{
				pending: {
					render() {
						return messages.USER.REMOVE
					},
				},
				success: {
					render() {
						handleClose();

						return messages.USER.REMOVED
					},
				},
				error: {
					render() {
						return messages.USER.FAILED
					},
				},
			}
		);
        
        
	}
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>Remove Master</Modal.Title>
				</Modal.Header>
				<Form >
				<Modal.Body>
					<h5 className="text-center">Are you sure you want to Remove Master?</h5>
				
					{/* <div className={`justify-content-center gap-3 ${styles.spacebetween}`}>
							
						</div> */}
				
				</Modal.Body>
				<Modal.Footer>
				<Button
								onClick={handleChangeStatus}
								className={`${styles.statusTrue} mt-3`}
							>
                Yes
							</Button>
							<Button
								onClick={handleClose}
								className={`${styles.statusFalse} mt-3`}
							>
                No
							</Button>
				</Modal.Footer>
				</Form>
			</div>
		</Modal>
	)
}

export default RemoveMaster