import React, { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import styles from  "../style.module.css"
import commonstyles from "../../../common/common.module.css"
import { messages } from "../../../utils/messages";
// import { AddPostSchema } from "../validations";
// import { uploadFiles } from "../../../common/api";
import { addTemplate, editTemplate, getSpecific } from "../api";
// import { POSTS } from "../../../utils/posts";
import { AddTemplateSchema } from "../validations";

const AddOrEdit = ({ showModal, handleClose}) => {
	const templateState = useSelector((state) => state.template);
	const [loading, setLoading]=useState(false)


	useEffect(() => {
		if(templateState.id!=="")
		{
			setLoading(true)
			getSpecific(templateState.id).then((temp)=>{
				
				TemplateFormik.setValues(temp.data)
					 setLoading(false)
			})

		}
		else{
			TemplateFormik.resetForm();
			// setLoading(false)
		}
	  
	}, [templateState.id])

	
	const TemplateFormik = useFormik({
		 validationSchema:AddTemplateSchema,
		initialValues: {
			bcc: "",
			cc: "",
			communicationType: "",
			emailBody: "",
			emailComment: "",
			emailSampleData: "",
			emailSubject: "",
			emailTo: "",
			fromEmail: "",
			fromName: "",
			name: "",
			pushImage: "",
			pushText: "",
			pushTo: "",
			pushUrl: "",
			smsText: "",
			smsTo: "",
			templateCode: "",
			userType:""
            
		},

		onSubmit: (values) => {
			toast.promise(
				templateState.editOrAdd==="add"?addTemplate(values):editTemplate(values, templateState.id),
				{
					pending: {
						render() {
							return templateState.editOrAdd==="add"?messages.MASTER.ADDING:messages.MASTER.EDIT
						},
					},
					success: {
						render() {
							TemplateFormik.resetForm();
							handleClose();
							return templateState.editOrAdd==="add"?messages.MASTER.ADDED:messages.MASTER.EDITED
						},
					},
					error: {
						render() {	
							return messages.MASTER.FAILED
						},
					},
				}
			);
			

			// uploadFiles(values.file).then((res)=>{
			// 	const {files}=res.data;
			// 	// const payload={link:values.link,  files, description:values.description}
			// 	const payload=values.link ? {link:values.link,  files, description:values.description}: {files, description: values.description}
			// 	toast.promise(
			// 		addAdminPost(payload),
			// 		{
			// 			pending: {
			// 				render() {
			// 					return messages.MASTER.ADDING
			// 				},
			// 			},
			// 			success: {
			// 				render() {
			// 					addPostFormik.resetForm();
			// 					handleClose();
			// 					setUploading(false);
			// 					return messages.MASTER.ADDED
			// 				},
			// 			},
			// 			error: {
			// 				render() {
			// 					setUploading(false);
			// 					return messages.MASTER.FAILED
			// 				},
			// 			},
			// 		}
			// 	);
            
			// }).catch(err=>{
			// 	setUploading(false);
			// 	toast.error(messages.MASTER.IMAGE_UPLOAD_FAILED);
			// })
			
		
		},
	});
	const handleAddTemplate=(e)=>{
		e.preventDefault();
		TemplateFormik.submitForm();

	}

	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
				TemplateFormik.resetForm();
			}}
		>
			{!loading?<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>
						{templateState.editOrAdd==="edit"?"Edit":"Add"} Template
					</Modal.Title>
				</Modal.Header>
				<Form >
				<Modal.Body>
					
						<p>Setting</p>
				
						<div className={styles.fieldStyle}>
							<Form.Group>
								<Form.Label>Communication Type</Form.Label>
								<Form.Select value={TemplateFormik.values.communicationType} onChange={TemplateFormik.handleChange} name="communicationType" aria-label="Default select example">
									<option value={""}>Select</option>
									<option  value="transactional">Transactional</option>
									<option value="marketing">marketing</option>
								</Form.Select>
							
							</Form.Group>
							{TemplateFormik.errors.communicationType &&
								TemplateFormik.touched.communicationType ? (
									<span className={commonstyles.error}>
										{ TemplateFormik.errors.communicationType}
									</span>
								) : null}
						</div>	

						<div className={styles.fieldStyle}>
							<Form.Group>
								<Form.Label>User Type </Form.Label>
								<Form.Select value={TemplateFormik.values.userType} onChange={TemplateFormik.handleChange} name="userType" aria-label="Default select example">
									<option>Select</option>
									<option value="professional">professional</option>
									<option value="business">business</option>
									<option value="servicepartner">servicepartner</option>
	  <option value="admin">admin</option>

								</Form.Select>
							
							</Form.Group>
							{TemplateFormik.errors.userType &&
								TemplateFormik.touched.userType ? (
									<span className={commonstyles.error}>
										{ TemplateFormik.errors.userType}
									</span>
								) : null}
						</div>		

						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>Template Code</Form.Label>
								<Form.Control
									value={TemplateFormik.values.templateCode}
									name="templateCode"
									onChange={TemplateFormik.handleChange}
									type="text"
									placeholder="Code"
									
								/>
							</Form.Group>
							{TemplateFormik.errors.templateCode &&
								TemplateFormik.touched.templateCode ? (
									<span className={commonstyles.error}>
										{ TemplateFormik.errors.templateCode}
									</span>
								) : null}
							
						</div>	
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>Name</Form.Label>
								<Form.Control name="name"
									value={TemplateFormik.values.name}
									onChange={TemplateFormik.handleChange}
									type="text"
									placeholder="Name"
									
								/>
							</Form.Group>
							{TemplateFormik.errors.name &&
								TemplateFormik.touched.name ? (
									<span className={commonstyles.error}>
										{ TemplateFormik.errors.name}
									</span>
								) : null}
							
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="exampleForm.ControlTextarea1">
								<Form.Label>Sample Data</Form.Label>
								<Form.Control
									as="textarea" rows={5}
									name="emailSampleData"
									value={TemplateFormik.values.emailSampleData}
									onChange={TemplateFormik.handleChange}
									type="text"
									placeholder="Sample Data"
									
								/>
							</Form.Group>
							
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>Comment</Form.Label>
								<Form.Control
									as="textarea" rows={5}
									onChange={TemplateFormik.handleChange}
									value={TemplateFormik.values.emailComment}
									name="emailComment"
									type="text"
									placeholder="Comment"
									
								/>
							</Form.Group>
							
						</div>
						<p>Email</p>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>From</Form.Label>
								<Form.Control
									value={TemplateFormik.values.fromEmail}
									name="fromEmail"
									onChange={TemplateFormik.handleChange}
									type="text"
									placeholder="Email"
									
								/>
							</Form.Group>
							
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>From Name</Form.Label>
								<Form.Control
									value={TemplateFormik.values.fromName}
									name="fromName"
									onChange={TemplateFormik.handleChange}
									type="text"
									placeholder="Name"
									
								/>
							</Form.Group>
							
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>CC</Form.Label>
								<Form.Control
									value={TemplateFormik.values.cc}
									name="cc"
									onChange={TemplateFormik.handleChange}

									type="text"
									placeholder="Email"
									
								/>
							</Form.Group>
							
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>BCC</Form.Label>
								<Form.Control
									value={TemplateFormik.values.bcc}
									type="text"
									placeholder="Email"
									name="bcc"
									onChange={TemplateFormik.handleChange}

									
								/>
							</Form.Group>
							
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>To</Form.Label>
								<Form.Control
									type="text"
									value={TemplateFormik.values.emailTo}
									placeholder="Email"
									name="emailTo"
									onChange={TemplateFormik.handleChange}
									
								/>
							</Form.Group>
							
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>Subject</Form.Label>
								<Form.Control
									value={TemplateFormik.values.emailSubject}
									type="text"
									name="emailSubject"
									onChange={TemplateFormik.handleChange}
									placeholder="Subject"
									
								/>
							</Form.Group>
							
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group  className="form-group" controlId="formName">
								<Form.Label>Body</Form.Label>
								<Form.Control
									as="textarea" rows={5}
									value={TemplateFormik.values.emailBody}
									onChange={TemplateFormik.handleChange}
									type="text"
									placeholder="Body"
									name="emailBody"
									
								/>
							</Form.Group>
							
						</div>
						<p>Push Notification</p>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>To</Form.Label>
								<Form.Control
									value={TemplateFormik.values.pushTo}
									name="pushTo"
									type="text"
									placeholder="To"
									onChange={TemplateFormik.handleChange}
									
								/>
							</Form.Group>
							
							
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>Notification Message</Form.Label>
								<Form.Control
									value={TemplateFormik.values.pushText}
									onChange={TemplateFormik.handleChange}
									as="textarea" rows={5}
									type="text"
									placeholder="Message"
									name="pushText"
									
								/>
							</Form.Group>
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>URL</Form.Label>
								<Form.Control
									value={TemplateFormik.values.pushUrl}
									name="pushUrl"
									onChange={TemplateFormik.handleChange}
									type="text"
									placeholder="URL"
									
								/>
							</Form.Group>
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>Image</Form.Label>
								<Form.Control
									value={TemplateFormik.values.pushImage}
									name="pushImage"
									type="text"
									placeholder="Image"
									onChange={TemplateFormik.handleChange}
									
								/>
							</Form.Group>
						</div>
						<p>SMS</p>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>To</Form.Label>
								<Form.Control
									value={TemplateFormik.values.smsTo}
									type="text"
									name="smsTo"
									placeholder="To"
									onChange={TemplateFormik.handleChange}

									
								/>
							</Form.Group>
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>Text Message</Form.Label>
								<Form.Control
									as="textarea" rows={5}
									name="smsText"
									value={TemplateFormik.values.smsText}
									type="text"
									onChange={TemplateFormik.handleChange}
									placeholder="Text Message"
									
								/>
							</Form.Group>
						</div>


						
					

					{/* <Form onSubmit={handleAddTemplate}>
					{/* <p>Settings</p> */}
					

				</Modal.Body>
				<Modal.Footer>
				<Button onClick={handleAddTemplate} className={`${styles.buttonss} mt-3`} type="submit">
							{templateState.editOrAdd==="edit"?"Edit":"Add"}
						</Button>
				</Modal.Footer>
				</Form>
			</div>:<div
				className={`${styles.spinner} d-flex justify-content-center`}
			>
				<div className="spinner-border" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>}
		</Modal>
	)
}

export default AddOrEdit