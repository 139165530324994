import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "../pages/Auth/Login";
import Dashboard from "../pages/Dashboard";
import Timesheet from "../pages/Timesheet";
import Invoice from "../pages/Invoice"
import Profile from "../pages/Profile";
import Professionels from "../pages/Professionels";
import Businesses from "../pages/Businesses";
import { RoutesUrl } from "../utils/RoutesUrl";
import Allwork from "../pages/Allwork";
import Master from "../pages/Masters";
import AdminUsers from "../pages/AdminUsers";
import Posts from "../pages/AllPosts"
import ViewMorePost  from "../pages/AllPosts/ViewMore/ViewMore";
import ViewMoreAdPost  from "../pages/AdvertisementPosts/ViewMore/ViewMore";
import Gallery from "../pages/Gallery";
import AllAdvertisementPosts from "../pages/AdvertisementPosts";
import WebsiteInquiries from "../pages/WebsiteInquiries";
import { Reviews } from "../pages/Reviews";
import ViewMoreReview from "../pages/Reviews/ViewMore/ViewMoreReview";
import Expenserepoet from "../pages/ExpenseRepoet";
import Communication from "../pages/Communication";
import Editform from "../pages/Editform";
import ViewMore from "../pages/Timesheet/ViewMore";
import ViewMoreExpense from "../pages/ExpenseRepoet/ViewMore"
import ViewMoreTimesheet from "../pages/Timesheet/ViewAll";
import ViewMoreInvoice from "../pages/Invoice/ViewAll";
import ViewMoreExpenseReport from "../pages/ExpenseRepoet/ViewAll";
import ToolTips from "../pages/ToolTips";
import CommunicationsTempalates from "../pages/CommunicationTempalates";
import EmailPreview from "../pages/CommunicationTempalates/EmailPreview"
import SmsPreview from "../pages/CommunicationTempalates/SmsPreview"
import NotificationPreview from "../pages/CommunicationTempalates/NotificationPeview"
import Subscripstion from "../pages/Subscriptions/index"
import SubscriptionPlans from "../pages/Subscriptions/routes/Plans/index"
import Boost from "../pages/Boost/index"
import BoostPlans from "../pages/Boost/routes/Plans/index"
import ViewProfile from "../pages/Professionels/routes/ViewProfile";
import Kpi from "../pages/kpi";
import Work from "../pages/Allwork/routes/Work";
import EditableDocument from "../pages/EditableDocuments";
import CMS from "../pages/CMS";
import ViewDocument from "../pages/EditableDocuments/routes/ViewDocument";
import CmsDocument from "../pages/CMS/routes/ViewDocument";
import TransactionHistory from "../pages/PaymentsHistory";
import Chat from "../pages/Chat/routes/Chat";
import ViewInvoiceDetail from "../pages/Invoice/ViewMore/ViewInvoiceDetail";
import TimesheetDetails from "../pages/Timesheet/ViewMore/TimesheetDetails";
import ExpenseDetails from "../pages/ExpenseRepoet/ViewMore/ExpenseDetails";



function MainRoutes() {
	return (
		<Router>
			<Routes>
				<Route exact path="/" element={<Login />} />
				<Route exact path={RoutesUrl.ALLWORK} element={<Allwork />} />
				<Route path={`${RoutesUrl.ALLWORK}/:id`} element={<Work/>}/>		
				<Route exact path={RoutesUrl.BUSINESSES} element={<Businesses />} />
				<Route path={RoutesUrl.DASHBOARD} element={<Dashboard />} />
				<Route path={RoutesUrl.LOGIN} element={<Login />} />
				<Route
					exact
					path={RoutesUrl.PROFESSIONALS}
					element={<Professionels />}
				/>
				<Route
					path={`${RoutesUrl.PROFESSIONAL_DETAILS}/:id`}
					element={<ViewProfile />}
				/>
				<Route
					exact
					path={RoutesUrl.MASTER}
					element={<Master />}
				/>
				<Route exact path={RoutesUrl.PROFILE} element={<Profile />} />
				<Route exact path={RoutesUrl.TIMESHEETS} element={<Timesheet />} />
				<Route exact path={RoutesUrl.INVOICE} element={<Invoice />} />
				<Route exact path={`${RoutesUrl.VIEW_INVOICE_DETAIL}/:id`} element={<ViewInvoiceDetail/>} />
				<Route exact path={`${RoutesUrl.VIEW_TIMESHEET_DETAIL}/:id`} element={<TimesheetDetails/>} />
				<Route exact path={`${RoutesUrl.VIEW_EXPENSE_DETAIL}/:id`} element={<ExpenseDetails/>} />
				<Route exact path={RoutesUrl.DOCUMENT} element={<EditableDocument/>} />
				<Route exact path={RoutesUrl.CMS} element={<CMS/>} />
				<Route exact path={`${RoutesUrl.DOCUMENT}/:id`} element={<ViewDocument/>} />
				<Route exact path={`${RoutesUrl.CMS}/:id`} element={<CmsDocument/>} />
				<Route exact path={`${RoutesUrl.transactions}`} element={<TransactionHistory/>} />
				<Route exact path={RoutesUrl.SPECIFIC_INVOICE} element={<ViewMoreInvoice/>} />
				<Route exact path={`${RoutesUrl.SPECIFIC_TIMESHEETS}`} element={<ViewMoreTimesheet />} />
				<Route exact path={`${RoutesUrl.EXPENSE_REPORT_ALL}`} element={<ViewMoreExpenseReport />} />
				<Route exact path={RoutesUrl.ADMIN_USERS} element={<AdminUsers />}/>
				<Route exact path={RoutesUrl.POSTS} element={<Posts />} />
				<Route exact path={`${RoutesUrl.POSTS}/:id`} element={<ViewMorePost/>}/>
				<Route exact path={RoutesUrl.ADVERTISEMENTPOSTS} element={<AllAdvertisementPosts />} />
				<Route exact path={`${RoutesUrl.ADVERTISEMENTPOSTS}/:id`} element={<ViewMoreAdPost/>}/>
				<Route exact path={RoutesUrl.GALLERY} element={<Gallery/>}/>
				<Route exact path={RoutesUrl.WEBSITE_INQUIRIES} element={<WebsiteInquiries />}/>
				<Route exact path={RoutesUrl.REVIEW} element={<Reviews />} />
				<Route exact path={RoutesUrl.EXPENSE} element={<Expenserepoet />} />
				<Route exact path={RoutesUrl.COMMUNICATION} element={<Communication />} />
				<Route exact path={RoutesUrl.EDITFORM} element={<Editform />} />
				<Route exact path={`${RoutesUrl.REVIEW}/:id`} element={<ViewMoreReview/>}/>
				<Route exact path={`${RoutesUrl.TIMESHEETS}/:id`} element={<ViewMore/>}/>
				<Route exact path={`${RoutesUrl.EXPENSE}/:id`} element={<ViewMoreExpense/>}/>
				<Route exact path={`${RoutesUrl.EXPENSE}/:id`} element={<ViewMoreExpense/>}/>
				<Route exact path={`${RoutesUrl.TOOLTIPS}`} element={<ToolTips/>}/>
				<Route exact path={`${RoutesUrl.TEMPALATES}`} element={<CommunicationsTempalates/>}/>
				<Route exact path={`${RoutesUrl.TEMPLATES_EMAIL}/:id`} element={<EmailPreview/>}/>
				<Route exact path={`${RoutesUrl.TEMPLATES_SMS}/:id`} element={<SmsPreview/>}/>
				<Route exact path={`${RoutesUrl.TEMPLATES_NOTIFICATIONS}/:id`} element={<NotificationPreview/>}/>
				<Route exact path={`${RoutesUrl.SUBSCRIPTIONS}`} element={<Subscripstion/>}/>
				<Route exact path={`${RoutesUrl.SUBSCRIPTION_PLANS}`} element={<SubscriptionPlans/>}/>
				<Route exact path={`${RoutesUrl.BOOST}`} element={<Boost/>}/>
				<Route exact path={`${RoutesUrl.BOOST_PLANS}`} element={<BoostPlans/>}/>
				<Route exact path={`${RoutesUrl.KPI}`} element={<Kpi/>}/>
				<Route exact path={`${RoutesUrl.CHAT}`} element={<Chat/>}/>
				<Route exact path={`${RoutesUrl.CHAT}/:id`} element={<Chat/>}/>


			</Routes>
		</Router>
	);
}

export default MainRoutes;
