import React, { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import styles from "../style.module.css";
import { getSpecifiedMaster, updateSpecifiedMaster } from "../api";
import commonstyles from "../../../common/common.module.css";
import { MASTER } from "../../../utils/master";
import { messages } from "../../../utils/messages";
import { EditMasterSchema } from "../validations";

const EditMaster = ({ showModal, handleClose }) => {
	const [loading, setLoading]=useState(true)
	const masterId = useSelector((state) => state.masters.id);

	useEffect(() => {
		setLoading(true)
		if(masterId&&masterId!=="")
		{	getSpecifiedMaster( masterId).then((res)=>{

			const {name}=res.data
			userDetailsFormik.setFieldValue(MASTER.FORMIK.NAME, name);	
			setLoading(false)
		})   
		}
     
	}, [showModal])
    
	const userDetailsFormik = useFormik({
		initialValues: {
			name: "",
			
		},
		validationSchema: EditMasterSchema,
		enableReinitialize: true,
		onSubmit: (values) => {
			toast.promise(
				updateSpecifiedMaster(masterId, values),
				{
					pending: {
						render() {
							return messages.MASTER.EDIT
						},
					},
					success: {
						render() {
							userDetailsFormik.resetForm();
							handleClose();

							return messages.MASTER.EDITED
						},
					},
					error: {
						render() {
							return messages.MASTER.FAILED
						},
					},
				}
			);
		
		
		},
	});
	const handleEditMaster=(e)=>{
		e.preventDefault();
		userDetailsFormik.submitForm();

	}
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>
						Edit Master
					</Modal.Title>
				</Modal.Header>
				{!loading?<Form onSubmit={handleEditMaster}>
				<Modal.Body>
					
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>User</Form.Label>
								<Form.Control
									type="text"
									placeholder="Name"
									value={userDetailsFormik.values.name}
									name={MASTER.FORMIK.NAME}
									onChange={userDetailsFormik.handleChange}
								/>
							</Form.Group>
							{userDetailsFormik.errors.name &&
                                userDetailsFormik.touched.name ? (
									<span className={commonstyles.error}>
										{userDetailsFormik.errors.name}
									</span>
								) : null}
						</div>		
					

						{/* <Button variant="outline-secondary" className="mb-2">
                    <i className="fas fa-plus"></i> Choose More Department
                </Button> */}
						{/* <hr /> */}
						{/* <Button variant="outline-secondary" className="mb-2">
                    <i className="fas fa-plus"></i> Add More University
                </Button> */}
				
				
				</Modal.Body>
				</Form>:(
						<div className="d-flex justify-content-center">
							<div className="spinner-border" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					)}
				<Modal.Footer>
				<Button className={`${styles.buttonss} mt-3`} type="submit">
							Edit
						</Button>
				</Modal.Footer>
			</div>
		</Modal>
	)
}

export default EditMaster