const storage = {
	getToken: () => {
		return window.localStorage.getItem("token");
	},
	getId: () => {
		return window.localStorage.getItem("id");
	},
	setToken: (token) => {
		window.localStorage.setItem("token", token);
	},
	setId: (id) => {
		window.localStorage.setItem("id", id);
	},
	clearToken: () => {
		window.localStorage.removeItem("id");
		window.localStorage.removeItem("token");
	},
};

export default storage;