import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import moment from "moment";

import styles from "./style.module.css";
import Sidebar from "../../../common/Sidebar/index";
import Topbar from "../../../common/Topbar";
import dlt from "../../../assets/images/dlt.png"
import companyLogo from "../../../assets/images/companyLogo.png"
import loc from "../../../assets/images/loc.png"
import infogr from "../../../assets/images/infogr.png"
import crtick from "../../../assets/images/crtick.png"
import pnedit from "../../../assets/images/pnedit.png"
import print from "../../../assets/images/print.png"
import dnlod from "../../../assets/images/dnlod.png"
import { getSpecificTimesheet } from "../api";
import ChangeStatus from "../modals/StatusChange";


function ViewMoreTimesheet() {
	const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
	const { id } = useParams();
	const [timesheetInfo, setTimesheetInfo] = useState();
	const [showChangeStatusModal, setChangeStatusModal] = useState({display:false, status:"rejected"});

	// const date=Date.now()
	// console.log(date.toString())
	const handleChangeStatusClose=()=>{
		setChangeStatusModal({display:false, status:"rejected"})
	}

	useEffect(() => {
		getSpecificTimesheet(id).then((res)=>{
			setTimesheetInfo(res.data);
			console.log(res.data, "data")
		})
	}, [])


	return (
		<div className={styles.pageOuter}>
			<Topbar/>
			<div className={styles.pageContainer}>
				<div className={styles.leftContainer}>
					<Sidebar/>
				</div>
				<div className={styles.rightContainer}>
					<h2>Timesheet Details</h2>
					<div className={styles.baseInfo}>
                       
						<div className='row'>
							{/* <div className='col-md-6'>
								<div className={styles.innerbaseinfo}>
									<div className={styles.leftInfo}>
										<img src={companyLogo} alt=""/>
										<h6>#{timesheetInfo?.work?.workNumber}</h6>
										<label>Hourly Contract</label>
									</div>
									<div className={styles.rightInfo}>
										<h5>{timesheetInfo?.work?.companyDetails?.name}</h5>
										<h5>{timesheetInfo?.companyDetails?.name}</h5>
										<h6><img src={loc} alt=""/>{timesheetInfo?.work?.street}</h6>
										<h6>Rate PerHour : ${timesheetInfo?.work?.rates.baseRate} </h6>
										<ul>
											<li>Start Date: {timesheetInfo?.startDate}</li>
											<li> Est End Date: {timesheetInfo?.endDate}</li>
											<li> Expense Terms : Net 30</li>
										</ul>
									</div>
								</div>
							</div> */}
							<div className='col-md-6'>
								<div className={`px-3 ${styles.innerbaseinfo}`}>
									<div className={styles.leftInfo}>
										<img src={companyLogo} alt=""/>
										<h6>#{timesheetInfo?.work?.workNumber}</h6>
										<label>Hourly Contract</label>
									</div>
									<div className={styles.rightInfo}>
										<h5>{timesheetInfo?.work?.companyDetails?.name}</h5>
										<h5>{timesheetInfo?.companyDetails?.name}</h5>
										<h6><img src={loc} alt=""/>{timesheetInfo?.work?.street}</h6>
										<h6>Rate PerHour : ${timesheetInfo?.work?.rates.baseRate} </h6>
										<ul>
											<li>Start Date: {moment(timesheetInfo?.startDate).format("MMM DD, YYYY")}</li>
											<li> Est End Date: {moment(timesheetInfo?.endDate).format("MMM DD, YYYY")}</li>
											<li> Expense Terms : Net 30</li>
										</ul>
									</div>
								</div>
							</div>
                            
						</div>
						<div className={styles.timeSheetdetils}>
							<div className={styles.timeSheetdetilshead}>
							<div className="col-12 topTipHover">
								<div className="w-100 d-flex align-items-center">
								<h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
									Timesheet Details
								</h4>
								<i>
									<img className="customeTolImg" src={infogr} alt="" />
								</i>
								{/* <Toltip
								/> */}
								</div>
							</div>
							<div className="col-12 mt-2">
								<div className="d-flex align-items-center justify-content-end g-2">
								<div className="px-1"><button className='bg-white border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'>View Signed Timesheet </button></div>
								<div className="px-1"><button className='bg-white border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'><i className="me-2"><img src={dlt} alt="" /></i> Delete</button></div>
								<div className="px-1"><button className='bg-white border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'><i className="me-2"><img src={crtick} alt="" /></i> Notify</button></div>
								<div className="px-1"> <button className='bg-white border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'><i className="me-2"><img src={pnedit} alt="" /></i> Edit</button></div>
								<div className="px-1"> <button className='bg-white border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'><i className="me-2"><img src={print} alt="" /></i> Print</button></div>
								<div className="px-1"> <button className='bg-white border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'><i className="me-2"><img src={dnlod} alt="" /></i> Export Data</button></div>
								</div>
							</div>
							</div>
							<div className={`table-responsive ${styles.tabletimesheet}`}>

							<table className="table align-middle">
								<tr>
								<td><span className="fw-semibold">Engineerss:</span></td>
								<td><span className="fw-normal">{timesheetInfo?.user?.name}</span></td>
								<td colSpan={2} className=" text-center"><span className="fw-semibold">Monday / Week Start Date</span></td>

								</tr>
								<tr>
								<td><span className="fw-semibold">Customer Company Name :</span></td>
								<td><span className="fw-normal">{ }</span></td>
								<td rowSpan={2} colSpan={2} className=" text-center"><span className="fw-normal">{moment(timesheetInfo?.startDate).format("MMM DD, YYYY")}</span></td>
								</tr>
								<tr>
								<td><span className="fw-semibold">Customer Contact:</span></td>
								<td colSpan={2} ><span className="fw-normal">{timesheetInfo?.work?.user?.name}</span></td>
								
								</tr>
								<tr>
								<td><span className="fw-semibold">Customer Email:</span></td>
								<td colSpan={2} ><span className="fw-normal">{timesheetInfo?.work?.user?.email}</span></td>
								</tr>
								<tr>
								<td><span className="fw-semibold">Work Number :</span></td>
								<td colSpan={2} ><span className="fw-normal">#{timesheetInfo?.work?.workNumber}</span></td>
								
								</tr>
							
							</table>

						</div>

						<div className={`table-responsive table-striped mb-2 ${styles.tabletimesheet}`}>
						<table className="table align-middle mb-0">
							<thead>
							<th>
								<span className="fw-semibold">Day</span>
							</th>
							<th>
								<span className="fw-semibold">Date</span>
							</th>
							<th>
								<span className="fw-semibold">Hours Worked Per Day</span>
							</th>
							<th>
								<span className="fw-semibold">Holiday</span>
							</th>
							<th>
								<span className="fw-semibold">Travel Time Hours</span>
							</th>
							<th>
								<span className="fw-semibold"> Total</span>
							</th>
							</thead>
							<tbody>
						<>
							<tr>
								<td>days</td>
								<td>MM DD, YYYY</td>
								<td>hourPerDay</td>
								<td></td>
								<td></td>
								<td>total</td>
							</tr>
							<tr>
								<td colSpan={6} className="p-0 border-0 ">                                              
									<textarea
									className="form-control w-100 bg-light rounded-0"
									placeholder="1"
									>
									</textarea>
								</td>
							</tr>
						</>
				
							</tbody>
						</table>
						</div>
							
							<div className={styles.personalDetials}>
								<div className={`${styles.Morefieldss} ${styles.topBrd}`}>
									<div className={styles.innerbrd}><b>Day</b></div>
									<div className={styles.innerbrd}><b>Date</b></div>
									<div className={styles.innerbrd}><b>Hours Worked Per Day</b></div>
									<div className={styles.innerbrd}><b>Sunday / Holidays</b></div>
									<div className={styles.innerbrd}><b>Travel Time Hours</b></div>
									<div className={styles.innerbrd}><b>Total</b></div>
								</div>
								{	weekDays.map((days, i)=><>
								<div className={`${styles.topBrd} ${styles.Morefieldss}`}>
                          
									<div className={styles.innerbrd}><b>{days}</b></div>
									<div className={styles.innerbrd}><b>{moment(timesheetInfo?.timesheetDetails[i]?.workedDate).format("MMM DD, YYYY")}</b></div>
									<div className={styles.innerbrd}><b>{timesheetInfo?.timesheetDetails[i]?.hourPerDay}</b></div>
									<div className={styles.innerbrd}><b></b></div>
									<div className={styles.innerbrd}><b></b></div>
									<div className={styles.innerbrd}><b>{timesheetInfo?.timesheetDetails[i]?.total}</b></div>
								</div>
								<div className={`${styles.topBrd} ${styles.brdColor}`}>
									<div className={styles.innerbrd}>Monday Work Report</div>
									<div className={styles.innerbrd}>AMKGH Smart fill Project Debuging PLC & HMI Programs</div>                           
								</div></>)}
							
							</div>


							<div className="row">
                                  <div className="col-6">
                                      <div className={`table-responsive ${styles.tabletimesheet}`}>
                                        <table className="table align-middle">
                                            <tr>
                                                <td><span className="fw-medium">Total Straight Time</span></td>
                                                <td className="text-end"><span className="fw-normal">{timesheetInfo?.totalStraightTime}</span></td>                          
                                            </tr>
                                            <tr>
                                                <td><span className="fw-medium">Total Over Time</span></td>
                                                <td className="text-end"><span className="fw-normal">{timesheetInfo?.overTime}</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="fw-medium">Sunday/Holiday</span></td>
                                                <td className="text-end"><span className="fw-normal">---</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="fw-medium">Total Double Time</span></td>
                                                <td className="text-end"><span className="fw-normal">{timesheetInfo?.doubleTime}</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="fw-medium">Total Travel TIme</span></td>
                                                <td className="text-end"><span className="fw-normal">{timesheetInfo?.totalTravelTime}</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="fw-medium">Total Hours</span></td>
                                                <td className="text-end"><span className="fw-normal">{timesheetInfo?.totalHours}</span></td>                        
                                            </tr>
                                        </table>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className={`table-responsive ${styles.tabletimesheet}`}>
                                        <table className="table align-middle">
                                            <tr>
                                                <td><span className="fw-semibold">Bill Rates</span></td>
                                                <td className="text-end"><span className="fw-normal"></span></td>                          
                                            </tr>
                                            <tr>
                                                <td><span className="fw-medium">Base Rate (per hour)</span></td>
                                                <td className="text-end"><span className="fw-normal">
                                                ${" "}
                                                {timesheetInfo?.work?.rates?.baseRate}
                                          </span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="fw-medium">OT Rate (per hour)</span></td>
                                                <td className="text-end"><span className="fw-normal">
                                                ${" "}
                                                {timesheetInfo?.work?.rates?.overTimeRate}</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="fw-medium">DT Rate (per hour)</span></td>
                                                <td className="text-end"><span className="fw-normal">
                                                ${" "}
                                                {timesheetInfo?.work?.rates?.doubleTimeRate}</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="fw-medium">Sunday/Holiday</span></td>
                                                <td className="text-end"><span className="fw-normal"> ${" "}
                                                {timesheetInfo?.work?.rates?.doubleTimeRate}</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="fw-medium">Travel Time Rate</span></td>
                                                <td className="text-end"><span className="fw-normal">${timesheetInfo?.work?.travels?.travelTimeRate}</span></td>                        
                                            </tr>
                                            <tr>
                                                <td><span className="fw-medium">Travel Trips Allowed (per month)</span></td>
                                                <td className="text-end"><span className="fw-normal">{timesheetInfo?.ssaRates?.travelTimeAllowed}</span>
                                                </td>                        
                                            </tr>
                                            <tr>
                                                <td><span className="fw-medium">Travel Hours Per Round Trip </span></td>
                                                <td className="text-end"><span className="fw-normal">{timesheetInfo?.work?.travels?.perRoundTrip===""?"0":timesheetInfo?.work?.travels?.perRoundTrip}</span>
                                                </td>                        
                                            </tr>
                                        </table>
                                      </div>
                                    </div>
                                  </div>


							
                           
						</div>
						<div className={styles.addComment}>
							<h4>Admin Comment</h4>
							<div className={styles.frogroup}>
								<textarea placeholder='No Comments found.....'></textarea>
							</div>
						</div>
						<div className={styles.commentBtn}>
							{timesheetInfo?.status==="pending"&&<><button onClick={()=>setChangeStatusModal({status:"approved", display:true})}>Approve</button>
								<button onClick={()=>setChangeStatusModal({status:"rejected", display:true})} className={styles.reject}>Reject</button></>}
							<button>Upload Signed Timesheet</button>
							<button>Print</button>
						</div>
					</div>
               
				</div>
			</div>
			<ChangeStatus
	  showModal={showChangeStatusModal.display}
	  status={showChangeStatusModal.status}
	  handleClose={handleChangeStatusClose}
			/> 
		</div>
	)
}
export default ViewMoreTimesheet