import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import styles from "./style.module.css";
import loginleft from "../../../assets/images/loginleft.png";
import logo from "../../../assets/images/logo.png";
import TextField from "../../../common/fields/TextField";
import PasswordFieldWithToggle from "../../../common/fields/PasswordFieldWithToggle";
import { loginDetailsSchema } from "./validations";
import { login } from "./api";
import storage from "../../../utils/storage";
import { RoutesUrl } from "../../../utils/RoutesUrl";
import { messages } from "../../../utils/messages";
 

const Login = () => {
	const initialValues = { email: "", password: "" };
	const [logging, setLogging] = useState(false)
	const navigate = useNavigate();

	useEffect(() => {
		if(storage.getToken())
		{
			navigate(RoutesUrl.PROFESSIONALS)

		}
   
	}, [])
  

	return (
		<div className={styles.loginOuter}>
			<div className={styles.loginLeft}>
				<div className={styles.logo}>
					<img src={logo} alt="logo" />
				</div>
				<div className="col-11 mx-auto text-center">
					<img src={loginleft} alt="loginleft" className="img-fluid" />
				</div>
				
			</div>
			<div className={styles.loginRight}>
			<div className="col-11 mx-auto text-start">
				<Formik
					
					initialValues={initialValues}
					validationSchema={loginDetailsSchema}
					onSubmit={(values) => {
						setLogging(true);
						const payload={
							userName:values.email,
							password:values.password

						}
						toast.promise(
							login(payload),
							{
								pending: {
									render() {
										return messages.AUTH.LOGIN
									},
								},
								success: {
									render({data}) {
										const {data:{authToken,userId}}=data
										storage.setToken(authToken);
										storage.setId(userId);
										navigate(RoutesUrl.PROFESSIONALS);            
										setLogging(false);
       									return messages.AUTH.LOGGED
									},
								},
								error: {
									render() {
										setLogging(false)
										return messages.AUTH.LOGINFAILED
									},
								},
							}
						);
					}}
				>
					{({ errors, touched }) => (
						<Form>
							<h4>Login</h4>
							<p>Welcome back! Please enter email and password</p>
							<div className={styles.formGroup}>
								{/* <TextField
									type="email"
									name="email"
									label="Email"
									// errors={touched?.email ? errors?.email : undefined}
									// touched={touched?.email}
									input={{ autoCapitalize: 'off' }} 
								/> */}
								<Field type='email' name='email' errors={touched?.email ? errors?.email : undefined} />
							</div>
							<div className="col-12">
								<TextField
									type="password"
									name="password"
									component={PasswordFieldWithToggle}
									label="Password"
									errors={touched?.password ? errors?.password : undefined}
									touched={touched?.password}
								/>
							</div>
							<div className={styles.formGroup}>
								<input disabled={logging} type="submit" value={"Login"} />
							</div>
						</Form>
					)}
				</Formik>
			</div>
			</div>
		</div>
	);
};

export default Login;
