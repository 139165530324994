/* eslint-disable indent */
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {  Dropdown, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import searchi from "./../../assets/images/search.png"
import {
  faEdit,
  faEllipsisH,
  faTrash,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import {
	faTableColumns,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./style.module.css";
import upload from "../../assets/images/upload.png";
// import cmlogo from "../../assets/images/cmlogo.png";
// import payone from "../../assets/images/payone.png"
// import paytwo from "../../assets/images/paytwo.png"
// import paythree from "../../assets/images/paythree.png"
import info from "../../assets/images/info.png";
// import grarow from "../../assets/images/grarow.png";
// import blarow from "../../assets/images/blarow.png";
import commonStyles from "../../common/common.module.css";
import { getDashboardInfo, getMasterByType } from "./api";
import { MASTER } from "../../utils/master";
import AddType from "./modals/AddType";
import RemoveMaster from "./modals/RemoveMaster";
import EditMaster from "./modals/EditMaster";
import ChangeStatus from "./modals/ChangeStatus";
import { editMasters } from "../../redux/slices/masterSlice";
import {useInfiniteScroll} from "../../common/infiniteScroll/useInfiniteScroll";
import { Layout } from "../../common/Layout";
import { downloadExcel } from "../../common/api";
import { ApiRoutes } from "../../utils/api_Routes";
import SelectColumns from "./modals/SelectColumns";
import { USER } from "../../utils/user";

function Master() {
  const dispatch = useDispatch();
  const [occupation, setOccupation] = useState([]);
  const [tab, setTab] = useState(MASTER.TYPE.OCCUPATIONS);
  const [activeTab, setActiveTab] = useState(MASTER.TYPE.OCCUPATIONS);
  const [masterType, setMasterType] = useState(MASTER.TYPE.OCCUPATIONS);
  const [showEditModal, setShowEditModal] = useState(false);
  const [orderBy, setOrderBy]=useState("All")
	const [order, setOrder]=useState(-1)
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showChangeStatusModal, setChangeStatusModal] = useState(false);
  const [showAddTypeModal, setShowAddTypeModal] = useState(false);
  const [showSelectModal, setShowSelectModal] = useState(false);
  const [dashboard, setDashboard]=useState();
  const [hasMore, setHasMore]=useState(true);
	const [page, setPage]=useState(1);
  const [loading, setLoading]=useState(true);
  const [search, setSearch]=useState("");
  const [selectedKey, setSelectedKey]=useState(MASTER.DEFAULT_COLUMNS_ARRAY)


	const elementRef = useRef(null);
  

  const handleExcelDownload=()=>{
		downloadExcel(ApiRoutes.MASTER.excel, {type:tab})
	}

  const handleAddTypeClose = () => {
    setShowAddTypeModal(false);
  };
  const handleEditClose = () => {
    setShowEditModal(false);
  };
  const handleRemoveClose = () => {
    setShowRemoveModal(false);
  };
  const handleChangeStatusClose = () => {
    setChangeStatusModal(false);
  };
  const handleSelectClose=()=>{
		setShowSelectModal(false)
	}
  const handleActiveOrder=(value)=>{
		setOrderBy(value);
		handleSearch();

	}
	const handleOrder=(value)=>{
		setOrder(Number(value))
		handleSearch()

	}

  
	const fetchMore=()=>{
    setLoading(true)
		getMasterByType(tab, {page, limit:10}, search, selectedKey, orderBy, order).then((res)=>{
      setLoading(false)
			const {data}=res;
			if(data.length===0)
			{
				setHasMore(false)
			}
			else{
        if(page===1){
          setOccupation(data);
        }
        else{
          setOccupation(prev=>[...prev, ...data])
          
        }
        setPage(prev=>prev+1)
			}
			setActiveTab(tab)
      setMasterType(tab)
		})
    getDashboardInfo().then((res)=>{
      setDashboard(res.data);
  })
	}

	useInfiniteScroll(occupation, elementRef, hasMore, fetchMore);

	useEffect(() => {
		setPage(1);
		setHasMore(true);

		setOccupation([])
	}, [tab])

  useEffect(() => {
    if (showAddTypeModal === false) {
      if (tab === masterType) {
        setPage(1);
			setHasMore(true);

			setOccupation([])
      } else {
        setTab(masterType);
      }
    }
  }, [showAddTypeModal]);
  useEffect(() => {
    if (showChangeStatusModal === false) {
      if (tab === masterType) {
        setPage(1);
			setHasMore(true);

			setOccupation([])
      } else {
        setTab(masterType);
      }
    }
  }, [showChangeStatusModal]);
  useEffect(() => {
    if (showEditModal === false) {
      if (tab === masterType) {
        setPage(1);
			setHasMore(true);

			setOccupation([])
      } else {
        setTab(masterType);
      }
    }
  }, [showEditModal]);
  useEffect(() => {
    if (showRemoveModal === false) {
      if (tab === masterType) {
        setPage(1);
			setHasMore(true);

			setOccupation([])
      } else {
        setTab(masterType);
      }
    }
  }, [showRemoveModal]);
  useEffect(() => {
    if (showSelectModal === false) {
      if (tab === masterType) {
        setPage(1);
			setHasMore(true);

			setOccupation([])
      } else {
        setTab(masterType);
      }
    }
  }, [showSelectModal]);

  const handleActivePanel = (type) => {
    setTab(type);
  };
  const handleSearchChange=(e)=>{
    setSearch(e.target.value)
  }
  const handleReset=()=>{
    setSearch("")
    setPage(1);
		setHasMore(true);

		setOccupation([])


  }
  const handleSearch=()=>{

    setPage(1);
		setHasMore(true);

		setOccupation([])
  }

  return (
   <Layout>
          <h2>Control Center</h2>
          <div className={styles.OuterTime}>
            <div className="row">
              <div className="col-md-4">
                <div className={styles.revenueBox}>
                  <div className={styles.revenueBoxHead}>
                    <h6>
                      All Occupations <img src={info} alt="" />{" "}
                    </h6>
                    {/* <span>
                      <img src={grarow} alt="" /> 30%
                    </span> */}
                  </div>
                  <h5>{dashboard?.totalOccupations}</h5>
                  {/* <div className={styles.revenueBoxFoter}>
                    <h4>
                      +$30,215 <span>from last month</span>
                    </h4>
                    <span>
                      More <img src={blarow} alt="" />
                    </span>
                  </div> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className={styles.revenueBox}>
                  <div className={styles.revenueBoxHead}>
                    <h6>
                      All Industries <img src={info} alt="" />{" "}
                    </h6>
                    {/* <span>
                      <img src={grarow} alt="" /> 15%
                    </span> */}
                  </div>
                  <h5>{dashboard?.totalIndustries}</h5>
                  {/* <div className={styles.revenueBoxFoter}>
                    <h4>
                      +1,647 <span>from last month</span>
                    </h4>
                    <span>
                      More <img src={blarow} alt="" />
                    </span>
                  </div> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className={styles.revenueBox}>
                  <div className={styles.revenueBoxHead}>
                    <h6>
                      All Manufacturers <img src={info} alt="" />{" "}
                    </h6>
                    {/* <span>
                      <img src={grarow} alt="" /> 18%
                    </span> */}
                  </div>
                  <h5>{dashboard?.totalManufacturers}</h5>
                  {/* <div className={styles.revenueBoxFoter}>
                    <h4>
                      +2,815 <span>from last month</span>
                    </h4>
                    <span>
                      More <img src={blarow} alt="" />
                    </span>
                  </div> */}
                </div>
              </div>
          
            </div>
          </div>
          <div className={styles.tableTime}>
            <div className={styles.topTime}>
              <h5>
                Occupations <i className="fas fa-info-circle"></i>
              </h5>
              <div className="text-end d-flex align-items-center justify-content-end">
              <div className={styles.topTimeInner}>
                <form className={styles.formGroup} 
                onSubmit={(e)=>{
                e.preventDefault();
                handleSearch()}
              }>
                <input value={search} onChange={handleSearchChange} disabled={loading}  type='text' placeholder='Search'/>
                <button className={styles.SaerchTag} onClick={handleSearch}  disabled={loading}><span><img src={searchi} alt=""/></span></button>
                <button disabled={loading} className={styles.resetButton} onClick={handleReset}  ><span>Reset</span></button>
              </form>
           
              </div>
              <div className="">
              <button onClick={handleExcelDownload} className={styles.exportButton}>
                  <img src={upload} alt="" /> Export
                </button> 
						<button onClick={()=>setShowSelectModal(true)} className={styles.selectButton}>
							<FontAwesomeIcon
								// className={commonStyles.iconmargin}
								icon={faTableColumns}
							/>
							<span>Select Columns</span>
						</button>
              </div>
              </div>
              {/* <div className={styles.filters}>
                <div className={styles.Formgroup}>
                  <label>Arrange : </label>
                  <select>
                    <option>Columns</option>
                    <option>Columns</option>
                  </select>
                </div>
                <div className={styles.Formgroup}>
                  <label>Select : </label>
                  <select>
                    <option>Columns</option>
                    <option>Columns</option>
                  </select>
                </div>
              </div> */}
              {/* <button>
                <img src={upload} alt="" /> Import
              </button> */}
              {/* <button>
                <img src={upload} alt="" /> Excel Format
              </button> */}
              {/* <button
                onClick={() => setShowAddTypeModal(true)}
                disabled={loading}
              >
                <img src={upload} alt="" /> Add{" "}
                {masterType === "softwarelicences"
                  ? "Software Licences"
                  : masterType.charAt(0).toUpperCase() + masterType.slice(1)}
              </button> */}
            </div>
            <div className={`${styles.extrafilter} ${styles.topTime}`}>
					
					<div className={styles.FilterBtn}>
						<Form.Group className="from-group" controlId="type">
							<Form.Label>Status:</Form.Label>
							<Form.Control
										
								value={orderBy}
								as="select"
								onChange={(e)=>handleActiveOrder(e.target.value)}
	
										
							>
								{
									["All", "approved", "pending", "rejected"].map((item)=><option value={item} key={1}>
										{item}
									</option>)
								}
								
									
							</Form.Control>
						</Form.Group>

						<Form.Group className="from-group" controlId="type">
							<Form.Label>Order By Date:</Form.Label>
							<Form.Control
										
								value={order}
								as="select"
								onChange={(e)=>handleOrder(e.target.value)}
	
										
							>
								
								<option value={1} key={1}>
						Ascending
								</option>
								<option value={-1} key={2}>
						Descending
								</option>
								
									
							</Form.Control>
						</Form.Group>
					
					</div>
				</div>
            <div className={styles.Tabstable}>
              <ul>
                <li
                  className={
                    activeTab === MASTER.TYPE.OCCUPATIONS && styles.active
                  }
                >
                  <Link
                    onClick={() => handleActivePanel(MASTER.TYPE.OCCUPATIONS)}
                  >
                    Occupations
                  </Link>
                </li>
                <li
                  className={
                    activeTab === MASTER.TYPE.INDUSTRIES && styles.active
                  }
                >
                  <Link
                    onClick={() => handleActivePanel(MASTER.TYPE.INDUSTRIES)}
                  >
                    Industries
                  </Link>
                </li>
                <li
                  className={
                    activeTab === MASTER.TYPE.MANUFACTURERS && styles.active
                  }
                >
                  <Link
                    onClick={() => handleActivePanel(MASTER.TYPE.MANUFACTURERS)}
                  >
                    Manufacturers
                  </Link>
                </li>
                <li
                  className={
                    activeTab === MASTER.TYPE.SOFTWARE_LICENCES && styles.active
                  }
                >
                  <Link
                    onClick={() =>
                      handleActivePanel(MASTER.TYPE.SOFTWARE_LICENCES)
                    }
                  >
                    Software Licences
                  </Link>
                </li>
                <li
                  className={
                    activeTab === MASTER.TYPE.APPLICATIONS && styles.active
                  }
                >
                  <Link
                    onClick={() => handleActivePanel(MASTER.TYPE.APPLICATIONS)}
                  >
                    Applications
                  </Link>
                </li>
                <li
                  className={
                    activeTab === MASTER.TYPE.REFERRALS && styles.active
                  }
                >
                  <Link
                    onClick={() => handleActivePanel(MASTER.TYPE.REFERRALS)}
                  >
                    Referrals
                  </Link>
                </li>
                <li
                  className={
                    activeTab === MASTER.TYPE.COMPANIES && styles.active
                  }
                >
                  <Link
                    onClick={() => handleActivePanel(MASTER.TYPE.COMPANIES)}
                  >
                    Companies
                  </Link>
                </li>
                <li
                  className={activeTab === MASTER.TYPE.DEGREE && styles.active}
                >
                  <Link onClick={() => handleActivePanel(MASTER.TYPE.DEGREE)}>
                    Degree
                  </Link>
                </li>
                <li
                  className={activeTab === MASTER.TYPE.SCHOOL && styles.active}
                >
                  <Link onClick={() => handleActivePanel(MASTER.TYPE.SCHOOL)}>
                    School
                  </Link>
                </li>
                <li
                  className={
                    activeTab === MASTER.TYPE.SPECIALIZATIONS && styles.active
                  }
                >
                  <Link
                    onClick={() =>
                      handleActivePanel(MASTER.TYPE.SPECIALIZATIONS)
                    }
                  >
                    Specializations
                  </Link>
                </li>
              </ul>
            </div>
              <div className={styles.Tbalelayout}>
                <table>
                  <tr>
                  {selectedKey.map((key)=><th key={key}>{key}</th>)}
                   
                    <th>Action</th>
                  </tr>
                  {occupation?.map((user, id) => (
                    
							<tr key={id}>
							
								
									<>{selectedKey.map((key, id)=>{
                    {console.log(user[key])}
										if(key===USER.COLUMN_KEYS.STATUS)
										{
										 return <td key={id}><label className={user.status==="rejected" && styles.inactive}>
												{user.status }
											</label></td>
										}
										if(key===USER.COLUMN_KEYS.LANGUAGES)
										{
											if(user?.languages?.length)
											{
												const languageProficienciesString = user.languages[0]; 


												const languageProficienciesArray = languageProficienciesString.split(";")
												const commaSeparatedString = languageProficienciesArray.join(", ");
												return  commaSeparatedString


											}
											else{
												return "No Language Specified"
											}
										}
										else if(key===USER.COLUMN_KEYS.NAME)
										{
											return <td><img className={styles.pp} src={USER.DEFAULT_PROFILE_PICTURE} alt="" /><span className={styles.capitalize}>{user[key]}</span></td>
										}
										else if(key===USER.COLUMN_KEYS.IS_AVAILABLE)
										{
											return <td key={id}>
												<label className={!user.isAvailable && styles.inactive}>
													{user.isAvailable ? "Available" : "Not Available"}
												</label>
													
											</td>
										}
										else if(key===USER.COLUMN_KEYS.IS_VERIFIED)
										{
											return <td key={id} className="text-center">{user.isVerified ? "Verified" : "Not Verified"}</td>
										}
										else if(key===USER.COLUMN_KEYS.WORK_STATUS)
										{
											return <td key={id}>
												<label className={!user.workStatus && styles.inactive}>
													{user.workStatus ? "Available" : "Not Available"}
												</label>
													
											</td>
										}
										else if(key===USER.COLUMN_KEYS.CREATED_AT||key===USER.COLUMN_KEYS.UPDATED_AT||key===USER.COLUMN_KEYS.LAST_LOGIN)
										{
											return <td key={id}>{moment(user[key]).format("DD MMMM YYYY")}</td>

										}
										else{
											if(key!==USER.COLUMN_KEYS.EMAIL&&key!==USER.COLUMN_KEYS.LINKEDIN_LINK)
											{
												return <td key={id} className={styles.capitalize}>{user[key]}</td>
											}
											if(key===USER.COLUMN_KEYS.LINKEDIN_LINK)
											{
												return <td key={id} ><a href={user[key]}>{user[key]}</a></td>
											}
										
											return <td key={id}>{occupation[key]}</td>
										}
									})}
								
									<td>
                  <td>
                          <Dropdown className={commonStyles.tddropdown}>
                            <Dropdown.Toggle className={styles.dropdownIcon}>
                              {/* <a className="me-2"></a> */}
                              <FontAwesomeIcon
                                // className={commonStyles.iconmargin}
                                icon={faEllipsisH}
                              />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  dispatch(editMasters({ id: occupation._id, currentStatus:occupation.status }));
                                  setShowEditModal(true);
                                }}
                              >
                                <FontAwesomeIcon
                                  size="xs"
                                  // className={commonStyles.iconmargin}
                                  className={commonStyles.iconmargin}
                                  icon={faEdit}
                                />
                                Edit
                              </Dropdown.Item>

                              <Dropdown.Item
                                onClick={() => {
                                  dispatch(editMasters({ id: occupation._id, currentStatus:occupation.status }));
                                  setShowRemoveModal(true);
                                }}
                              >
                                <FontAwesomeIcon
                                  size="xs"
                                  className={commonStyles.iconmargin}
                                  icon={faTrash}
                                />
                                Remove
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  dispatch(editMasters({ id: occupation._id, currentStatus:occupation.status }));
                                  setChangeStatusModal(true);
                                }}
                              >
                                <FontAwesomeIcon
                                  onClick={() => {}}
                                  size="xs"
                                  className={commonStyles.iconmargin}
                                  icon={faCircleCheck}
                                />
                                Change Status
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
									</td></>
									
								
							</tr>
						))}
                </table>
                {hasMore&&<div ref={elementRef}><div className= {`${styles.spinner} d-flex justify-content-center`}>
								<div className="spinner-border" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							</div></div>}
              </div>
          </div>
      
      <AddType
        showModal={showAddTypeModal}
        handleClose={handleAddTypeClose}
        type={masterType}
      />
      <EditMaster showModal={showEditModal} handleClose={handleEditClose} type={masterType}/>
      <RemoveMaster
        showModal={showRemoveModal}
        handleClose={handleRemoveClose}
        type={masterType}
      />
      <ChangeStatus
        showModal={showChangeStatusModal}
        handleClose={handleChangeStatusClose}
      />
      	<SelectColumns showModal={showSelectModal}
				handleClose={handleSelectClose}
				selectedKeys={selectedKey}
				setSelectedKeys={setSelectedKey}
			/>
   </Layout>
  );
}

export default Master;
