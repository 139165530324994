import React from "react";
import { useParams } from "react-router-dom";

function TimesheetDetails() {
  const { id } = useParams();

  console.log(id, "id");
  return (
    <div>
      <h1>{id}</h1>
    </div>
  );
}

export default TimesheetDetails;
