import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import styles from "../style.module.css";
import { removeInquiry } from "../api";
import { messages } from "../../../utils/messages";

const RemoveInquiry = ({ showModal, handleClose }) => {
	const inquiryId = useSelector((state) => state.inquiry.id);

	const handleRemoveInquiry = () => {
		toast.promise(removeInquiry(inquiryId), {
			pending: {
				render() {
					return messages.WEBSITE_INQUIRY.REMOVE;
				},
			},
			success: {
				render() {
					handleClose();

					return messages.WEBSITE_INQUIRY.REMOVED;
				},
			},
			error: {
				render() {
					return messages.WEBSITE_INQUIRY.FAILED;
				},
			},
		});
	};
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>Remove Inquiry</Modal.Title>
				</Modal.Header>
				<Form>
				<Modal.Body>
					<h5 className="text-center">Are you sure you want to Remove Inquiry?</h5>
					
					{/* <div className={`justify-content-center gap-3 ${styles.spacebetween}`}>
							
						</div> */}
					
				</Modal.Body>
				<Modal.Footer>
				<Button
								onClick={handleRemoveInquiry}
								className={`${styles.statusTrue} mt-3`}
							>
                Yes
							</Button>
							<Button
								onClick={handleClose}
								className={`${styles.statusFalse} mt-3`}
							>
                No
							</Button>
				</Modal.Footer>
				</Form>
			</div>
		</Modal>
	);
};

export default RemoveInquiry;
