import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { blockUser } from "../api";
import styles from '../../Boost/style.module.css'
import { messages } from "../../../utils/messages";

const BlockModal=({ handleClose, userInfo ,onBlockSuccess,isBlocked})=>{

    const handleChangeStatus=()=>{
		if(userInfo){toast.promise(
			blockUser(userInfo.id),
			{
				pending: {
					render() {
						return isBlocked?"Unblocking":"Blocking"
					},
				},
				success: {
					render() {
                        onBlockSuccess()
						handleClose();

						return `User ${isBlocked?"Unblocked":"Blocked"} Successfully`
					},
				},
				error: {
					render() {
						return "Failed"
					},
				},
			}
		);}
        
        
	}
	return (
		<Modal
			show={true}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>{isBlocked? "Unblock":"block"}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					
					<Form >
					<div className={`justify-content-center gap-3 ${styles.spacebetween}`}>
							<Button
								onClick={handleChangeStatus}
								className={`${styles.statusbuttons} mt-3`}
							>
                Yes
							</Button>
							<Button
								onClick={handleClose}
								className={`${styles.statusbuttons} mt-3`}
							>
                No
							</Button>
						</div>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	)
}




export default BlockModal