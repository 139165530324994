import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faEllipsisH, faEye, faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";

import styles from "./style.module.css";
import commonStyles from "../../common/common.module.css";
import searchi from "./../../assets/images/search.png"
// import email from "../../assets/images/email.png"
// import phone from "../../assets/images/phone.png"
// import dots from "../../assets/images/dots.png";
import upload from "../../assets/images/upload.png";
// import accepttwo from "../../assets/images/accepttwo.png";
// import acceptone from "../../assets/images/acceptone.png";
// import sort from "../../assets/images/sort.png";
import { getWorkByStatus, getWorkDashboard } from "./api";
import { WORK } from "../../utils/work";
import info from "../../assets/images/info.png"
// import grarow from "../../assets/images/grarow.png"
import {useInfiniteScroll} from "../../common/infiniteScroll/useInfiniteScroll";
import { Layout } from "../../common/Layout";
import { editWork } from "../../redux/slices/workSlice";
import ChangeStatus from "./modals/ChangeStatus";
import { downloadExcel } from "../../common/api";
import { ApiRoutes } from "../../utils/api_Routes";
import { RoutesUrl } from "../../utils/RoutesUrl";
import SelectColumns from "./modals/SelectColumns";
import { getAllCountry } from "../Professionels/api";

function Allwork() {
	const [work, setWork] = useState([]);
	const [tab, setTab] = useState(WORK.STATUS.ALL);
	const [activeTab, setActiveTab] = useState(WORK.STATUS.ALL);
	const [totalRecords, setTotalRecords] = useState();
	const [dashboardData, setDashboardData]=useState();
	const [hasMore, setHasMore]=useState(true);
	const [page, setPage]=useState(1);
	const [allCountry, setAllCountry]=useState([]);
	const [order, setOrder]=useState(-1)
	const [country, setCountry]=useState(0);
	const elementRef = useRef(null);
	const [showChangeStatusModal, setChangeStatusModal] = useState(false);
	const dispatch =useDispatch()
	const [loading, setLoading]=useState(true);
	const [search, setSearch]=useState("");
	const [exportLoader, setExportLoader]=useState(false)
	const [showSelectModal, setShowSelectModal] = useState(false);
	const [selectedKey, setSelectedKey]=useState(["userName", "workType", "workStatus", "createdAt"])
	const navigate=useNavigate()

	const handleExcelDownload = () => {
		setExportLoader(true)
		downloadExcel(ApiRoutes.WORK.excel).then(()=>{
			setExportLoader(false)
		})
	}
	const handleSelectClose=()=>{
		setShowSelectModal(false)
	}

	const fetchMore=()=>{
		setLoading(true);
		getWorkByStatus(tab, {page, limit:10}, search, selectedKey, order, country).then((res)=>{
			setLoading(false)
			const {data, pagination}=res;
			setTotalRecords(pagination.totalRecord);
			if(data.length===0)
			{
				setHasMore(false)
			}
			else{
				if(page===1){
					setWork(data);
				}
				else{
					setWork(prev=>[...prev, ...data])
				}
				setPage(prev=>prev+1)
			}
			setActiveTab(tab)
		})
		getWorkDashboard().then((res)=>{
			if(res){
				const {data}=res
				setDashboardData(data)
			}
		})
	}

	useInfiniteScroll(work, elementRef, hasMore, fetchMore);
	useEffect(() => {
		getAllCountry().then((res)=>{
			setAllCountry(res.data)
		})
	
	}, [])
	useEffect(() => {
		if (showChangeStatusModal === false) {
			setPage(1);
			setHasMore(true);
			setWork([])
		}
	}, [showChangeStatusModal]);
	useEffect(() => {
		if (showSelectModal === false) {
			setPage(1);
			setHasMore(true);
			setWork([])
		}
	}, [showSelectModal]);

	useEffect(() => {
		setPage(1);
		setHasMore(true);

		setWork([])
	}, [tab])

	const handleActivePanel = (status) => {
		setTab(status);
	};
	const handleChangeStatusClose=()=>{
		setChangeStatusModal(false)
	}
	const handleSearchChange=(e)=>{
		setSearch(e.target.value)
	}
	const handleCountry=(value)=>{
		setCountry((value));
		handleSearch();

	}
	const handleOrder=(value)=>{
		setOrder(Number(value))
		handleSearch()

	}
	const handleReset=()=>{
		setSearch("")
		setPage(1);
		setHasMore(true);

		setWork([])

	}
	const handleSearch=()=>{

		setPage(1);
		setHasMore(true);

		setWork([])
	}

	return (
		<Layout>
			<h2>Work Dashboard / All Work</h2>
			<div className={styles.OuterTime}>
				<div className='row'>
						
					<div className='col'>
						<div className={styles.revenueBox}>
							<div className={styles.revenueBoxHead}>
								<h6>All Work </h6>
								{/* <span><img src={grarow} alt="" /> 30%</span> */}
							</div>
							<h5>{dashboardData?.totalWork}</h5>
								
                  
						</div>
					</div>
					<div className='col'>
						<div className={styles.revenueBox}>
							<div className={styles.revenueBoxHead}>
								<h6>Shared <img src={info} alt="" /> </h6>
								{/* <span><img src={grarow} alt="" /> 30%</span> */}
							</div>
							<h5>{dashboardData?.shared}</h5>
								
                  
						</div>
					</div>
					<div className='col'>
						<div className={styles.revenueBox}>
							<div className={styles.revenueBoxHead}>
								<h6>Searches <img src={info} alt="" /> </h6>
								{/* <span><img src={grarow} alt="" /> 30%</span> */}
							</div>
							<h5>{dashboardData?.searches}</h5>
									
                  
						</div>
					</div>
					<div className='col'>
						<div className={styles.revenueBox}>
							<div className={styles.revenueBoxHead}>
								<h6>Re-apply Requests <img src={info} alt="" /> </h6>
								{/* <span><img src={grarow} alt="" /> 30%</span> */}
							</div>
							<h5>{dashboardData?.reApplyRequest}</h5>
									
                  
						</div>
					</div>
					<div className='col'>
						<div className={styles.revenueBox}>
							<div className={styles.revenueBoxHead}>
								<h6>Professional Applications <img src={info} alt="" /> </h6>
								{/* <span><img src={grarow} alt="" /> 30%</span> */}
							</div>
							<h5>{dashboardData?.professionalApplication}</h5>
								
						</div>
					</div>
				</div>
			</div>

			<div className={styles.topHeaderongoing}>
				
				{/* <div className={styles.rightshowcase}>
							<span>
								<img src={accepttwo} alt="" /> Fulltime
							</span>
							<span>
								<img src={acceptone} alt="" /> Contcart
							</span>
							<div className={styles.FormGroup}>
								<span>
									<img src={sort} alt="" />{" "}
								</span>
								<select>
									<option>Newest</option>
								</select>
							</div>
						</div> */}
			</div>

			<div className={styles.tableTime}>
				<div className={styles.topTime}>
					<h5>
                Users <i className="fas fa-info-circle"></i>
					</h5>
					<div className="text-end d-flex align-items-center">
						<div className={styles.topTimeInner}>
							<form className={styles.formGroup} 
								onSubmit={(e)=>{
									e.preventDefault();
									handleSearch()}
								}>
							
								<input value={search} onChange={handleSearchChange} disabled={loading}  type='text' placeholder='Search'/>
								<button className={styles.SaerchTag} onClick={handleSearch}  disabled={loading}><span><img src={searchi} alt=""/></span></button>
								<button disabled={loading} className={styles.resetButton} onClick={handleReset}  ><span>Reset</span></button>
							</form>
						</div>
						<div className="ms-2">
							<button disabled={exportLoader} onClick={handleExcelDownload} className={styles.exportButton}>
								<img src={upload}  alt="" /> {exportLoader?"Exporting...":"Export"}
							</button>
							<button onClick={()=>setShowSelectModal(true)} className={styles.selectButton}>
								<FontAwesomeIcon
									// className={commonStyles.iconmargin}
									icon={faTableColumns}
								/>
								<span>Select Columns</span>
							</button>
							
						</div>
					</div>
				</div>
				<div className={`${styles.extrafilter} ${styles.topTime}`}>
					
					<div className={styles.FilterBtn}>
						

						<Form.Group className="from-group" controlId="type">
							<Form.Label>Order By Date:</Form.Label>
							<Form.Control
										
								value={order}
								as="select"
								onChange={(e)=>handleOrder(e.target.value)}
	
										
							>
								
								<option value={1} key={1}>
						Ascending
								</option>
								<option value={-1} key={2}>
						Descending
								</option>
								
									
							</Form.Control>
						</Form.Group>
						<Form.Group className="from-group" controlId="type">
							<Form.Label>Country:</Form.Label>
							<Form.Control
										
								value={country}
								as="select"
								onChange={(e)=>handleCountry(e.target.value)}						
							>
								
								<option value={0} key={0}>
						Select Country
								</option>
								{allCountry.map((country)=><option className={styles.capitalize} value={country._id} key={country.id}>
									<span className={styles.capitalize}>{country.name}</span>
								</option>)}
									
							</Form.Control>
						</Form.Group>
					</div>
				</div>
				
				<div className={styles.Tabstable}>
					<ul>
						<li className={activeTab === WORK.STATUS.ALL && styles.active}>
							<Link onClick={() => handleActivePanel(WORK.STATUS.ALL)}>
                    All{" "}
							</Link>
						</li>
						<li
							className={activeTab === WORK.STATUS.PENDING && styles.active}
						>
							<Link onClick={() => handleActivePanel(WORK.STATUS.PENDING)}>
                    Pending
							</Link>
						</li>
						<li
							className={
								activeTab === WORK.STATUS.APPROVED && styles.active
							}
						>
							<Link onClick={() => handleActivePanel(WORK.STATUS.APPROVED)}>
                    Approved
							</Link>
						</li>
						<li className={activeTab === WORK.STATUS.OPEN && styles.active}>
							<Link onClick={() => handleActivePanel(WORK.STATUS.OPEN)}>
                    Open
							</Link>
						</li>
						<li
							className={activeTab === WORK.STATUS.ONGOING && styles.active}
						>
							<Link onClick={() => handleActivePanel(WORK.STATUS.ONGOING)}>
                    Ongoing
							</Link>
						</li>
						<li
							className={activeTab === WORK.STATUS.CLOSED && styles.active}
						>
							<Link onClick={() => handleActivePanel(WORK.STATUS.CLOSED)}>
                    Closed
							</Link>
						</li>
						<li
							className={activeTab === WORK.STATUS.FILLED && styles.active}
						>
							<Link onClick={() => handleActivePanel(WORK.STATUS.FILLED)}>
                    Filled{" "}
							</Link>
						</li>
					</ul>
					{/* <div className={styles.filters}>
								<div className={styles.Formgroup}>
									<label>Arrange : </label>
									<select>
										<option>Columns</option>
										<option>Columns</option>
									</select>
								</div>
								<div className={styles.Formgroup}>
									<label>Select : </label>
									<select>
										<option>Columns</option>
										<option>Columns</option>
									</select>
								</div>
							</div> */}
				</div>
				
				<div className={styles.Tbalelayout}>
					<table>
						<tr>
							{/* <th>  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/> ID</th> */}
							{selectedKey.map((key)=>{
								return <th>{key}</th>
							})}
						
							<th>Action</th>
						</tr>

						{work.map((work, id) => (
							<tr key={id}>
								{selectedKey.map((key)=>{
									if(key==="workType")
									{
										return <td>{work.workType.split("_").map((itemm)=>{
											return itemm[0].toUpperCase()+itemm.slice(1)+" "
										})}</td>
									}
									else if(key==="workStatus")
									{
										return <td>{work.workStatus[0].toUpperCase()+work.workStatus.slice(1)}</td>
									}
									else if(key==="createdAt"||key==="updatedAt"||key==="startDate"||key==="endDate")
									{
										
										return <td>{moment(work[key]).format("MMM DD, YYYY")}</td>
									}
									else{
										if(work[key]===true)
										{
											return <td>{"Yes"}</td>
										}
										else if(work[key]===false){
											return <td>{work[key]}</td>
										}
										else{
											return <td>{work[key]}</td>
										}
									
									}
								})}
								{/* <td> <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>  #4566</td> */}
								{/* <td>
									<span>
										<img className={styles.pp} src={work.userProfilePicture} alt="" />
									</span>
									
									<div>{work.userName}</div>
								</td> */}
								{/* <td>{work.workType.split("_").map((itemm)=>{
									return itemm[0].toUpperCase()+itemm.slice(1)+" "
								})}</td> */}
								{/* <td>{work.workStatus[0].toUpperCase()+work.workStatus.slice(1)}</td> */}

								{/* <td>{moment(work.created_at).format("MMM DD, YYYY")}</td> */}
								{/* <td><span><img src={email} alt="" /></span><span><img src={phone} alt="" /></span></td> */}
								{/* <td>
									<td>
										<label className={!work.status?styles.rejected: styles.approved}>
											{work.status?"Active":"In Active"}
										</label>
									</td>
								</td> */}
								{/* <td>
												<img src={dots} alt="" />
											</td> */}
								<td>
									<Dropdown className={commonStyles.tddropdown}>
										<Dropdown.Toggle className={styles.dropdownIcon}>
											{/* <a className="me-2"></a> */}
                                                               
											<FontAwesomeIcon
												// className={commonStyles.iconmargin}
												icon={faEllipsisH}
											/>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											<Dropdown.Item
												onClick={() => {
													// dispatch(editWork({ id: user._id }));
													// setShowEditModal(true);
													navigate(`${RoutesUrl.ALLWORK}/${work._id}`)
												}}
											>
												<FontAwesomeIcon
													size="xs"
													className={commonStyles.iconmargin}
													icon={faEye}
												/>
																View More
											</Dropdown.Item>

											{(work.workStatus===WORK.STATUS.PENDING||work.workStatus===WORK.STATUS.APPROVED||work.workStatus===WORK.STATUS.REJECTED)&&<Dropdown.Item
												onClick={() => {
													dispatch(editWork({ id: work._id }));
													setChangeStatusModal(true);
												}}
											>
												<FontAwesomeIcon
													size="xs"
													className={commonStyles.iconmargin}
													icon={faCircleCheck}
												/>
																Change Status
											</Dropdown.Item>}
																
										</Dropdown.Menu>
									</Dropdown>
								</td>
							</tr>
						))}
					</table>
					{hasMore&&<div ref={elementRef}><div className= {`${styles.spinner} d-flex justify-content-center`}>
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div></div>}
				</div>
			</div>
			<div className={`d-flex align-items-center justify-content-between mt-3 ${styles.leftshowcase}`}>
				<div className="col-6"><h6>Showing {totalRecords} Ongoing works </h6></div>
				{/* <div className="col-6 text-end"><p>Based your preferences</p></div> */}
				
				
			</div>
			<ChangeStatus showModal={showChangeStatusModal} handleClose={handleChangeStatusClose}/>
			<SelectColumns showModal={showSelectModal}
				handleClose={handleSelectClose}
				selectedKeys={selectedKey}
				setSelectedKeys={setSelectedKey}
			/>

		</Layout>
	);
}

export default Allwork;
