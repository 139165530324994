import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import searchi from "./../../assets/images/search.png"
import {
	faCircleCheck,
	faEllipsisH,
	faEye,
	faTableColumns,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import moment from "moment";

import styles from "./style.module.css";
import upload from "../../assets/images/upload.png";
import tabpro from "../../assets/images/tabpro.png";
import commonStyles from "../../common/common.module.css";
import { getAllPosts } from "./api";
import ChangeStatus from "./modals/ChangeStatus";
import { editPost } from "../../redux/slices/postSlice";
import { RoutesUrl } from "../../utils/RoutesUrl";
import { POSTS } from "../../utils/posts";
import AddPost from "./modals/AddPost";
import {useInfiniteScroll} from "../../common/infiniteScroll/useInfiniteScroll";
import { Layout } from "../../common/Layout";
import { downloadExcel } from "../../common/api";
import { ApiRoutes } from "../../utils/api_Routes";
import SelectColumns from "./modals/SelectColumns";
import { Form } from "react-bootstrap";


function AllAdvertisementPosts() {
	const dispatch = useDispatch();
	const [posts, setPosts] = useState([]);
	const [tab, setTab] = useState(POSTS.STATUS.ALL);
	const [activeTab, setActiveTab] = useState(POSTS.STATUS.ALL);
	const navigate=useNavigate()
	const [showChangeStatusModal, setChangeStatusModal] = useState(false);
	const [showAddPostModal, setAddPostModal] = useState(false);
	const [hasMore, setHasMore]=useState(true);
	const [page, setPage]=useState(1);
	const [loading, setLoading]=useState(true);
	const [search, setSearch]=useState("");
	const [showSelectModal, setShowSelectModal] = useState(false);
	const [selectedKey, setSelectedKey]=useState(["userName", "type", "updatedAt", "createdAt", "status"])
	const elementRef = useRef(null);
	const [order, setOrder]=useState(-1)


	const handleExcelDownload =()=>{
		downloadExcel(ApiRoutes.POSTS.excel, {type:POSTS.TYPE.ADVERTISEMENT})
	}
	
	const handleSelectClose=()=>{
		setShowSelectModal(false)
	}
	const handleOrder=(value)=>{
		setOrder(Number(value))
		handleSearch()

	}

	const fetchMore=()=>{
		setLoading(true)
		getAllPosts(tab, {page, limit:10}, search, selectedKey, order).then((res)=>{
			setLoading(false);
			const {data}=res;
			if(data.length===0)
			{
				setHasMore(false)
			}
			else{
				if(page===1)
				{
					setPosts(data)
				}
				else{
					setPosts(prev=>[...prev, ...data])
				}
				setPage(prev=>prev+1)
			}
			setActiveTab(tab)
		})
	}


	useInfiniteScroll(posts, elementRef, hasMore, fetchMore);


	const handleChangeStatusClose = () => {
		setChangeStatusModal(false);
	};
	const handleAddPostClose = () => {
		setAddPostModal(false);
	};
	

	useEffect(() => {
		setPage(1);
		setHasMore(true);

		setPosts([])
	}, [tab])
	useEffect(() => {
		if(showAddPostModal===false)
		{
			setPage(1);
			setHasMore(true);

			setPosts([])
		}
	}, [showAddPostModal])
	useEffect(() => {
		if(showChangeStatusModal===false)
		{
			setPage(1);
			setHasMore(true);

			setPosts([])
		}
	}, [showChangeStatusModal])
	
	
	const handleActivePanel = (type) => {
		setTab(type);
	};

	const handleSearchChange=(e)=>{
		setSearch(e.target.value)
	}
	const handleReset=()=>{
		setSearch("")
		setPage(1);
		setHasMore(true);

		setPosts([])


	}
	const handleSearch=()=>{

		setPage(1);
		setHasMore(true);

		setPosts([])
	}


	return (
		<Layout>
			<h2>Advertisement Posts</h2>
			{/*<div className={styles.OuterTime}>
				 <div className="row">
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
						</div> 
			</div>*/}
			<div className={styles.tableTime}>
				<div className={styles.topTime}>
					<h5>
							Advertisement Posts <i className="fas fa-info-circle"></i>
					</h5>
					{/* <button onClick={()=>setAddPostModal(true)}>
								<FontAwesomeIcon icon={faPlus} /> Add
							</button> */}
					<div className={styles.topTimeInner}>
						<form className={styles.formGroup} 
							onSubmit={(e)=>{
								e.preventDefault();
								handleSearch()}
							}>
							<input value={search} onChange={handleSearchChange} disabled={loading}  type='text' placeholder='Search'/>
							<button className={styles.SaerchTag} onClick={handleSearch}  disabled={loading}><span><img src={searchi} alt=""/></span></button>
							<button disabled={loading} className={styles.resetButton} onClick={handleReset}  ><span>Reset</span></button>
							
						</form>
						<button onClick={handleExcelDownload} className = {styles.exportButton}>
							<img src = {upload} alt=""/> Export
						</button>
						<button onClick={()=>setShowSelectModal(true)} className={`ms-1 ${styles.selectButton}`}>
							<i className="me-2"><FontAwesomeIcon
								// className={commonStyles.iconmargin}
								icon={faTableColumns}
							/></i>
							<span>Select Columns</span>
						</button>
					</div>
				</div>
				<div className={`mt-3 ${styles.extrafilter} ${styles.topTime}`}>
					
					<div className={styles.FilterBtn}>
					

						<Form.Group className="from-group" controlId="type">
							<Form.Label>Order By Date:</Form.Label>
							<Form.Control
										
								value={order}
								as="select"
								onChange={(e)=>handleOrder(e.target.value)}										
							>
								<option value={1} key={1}>
									Ascending
								</option>
								<option value={-1} key={2}>
									Descending
								</option>
									
							</Form.Control>
						</Form.Group>
					
					</div>
				</div>
				<div className={styles.Tabstable}>
					<ul>
						<li className={activeTab === POSTS.STATUS.ALL && styles.active}>
							<Link onClick={() => handleActivePanel(POSTS.STATUS.ALL)}>
										All Posts
							</Link>
						</li>
								
						<li className={activeTab === POSTS.STATUS.PENDING && styles.active}>
							<Link onClick={() => handleActivePanel(POSTS.STATUS.PENDING)}>
										Pending
							</Link>
						</li>
						<li className={activeTab === POSTS.STATUS.APPROVED&& styles.active}>
							<Link onClick={() => handleActivePanel(POSTS.STATUS.APPROVED)}>
										Approved
							</Link>
						</li>
						<li className={activeTab === POSTS.STATUS.REJECTED&& styles.active}>
							<Link onClick={() => handleActivePanel(POSTS.STATUS.REJECTED)}>
										Rejected
							</Link>
						</li>
								
					</ul>
				</div>

				<div className={styles.Tbalelayout}>
					<table>
						<tr>
							{selectedKey.map((key)=>{
								return <th>{key}</th>
							})}
						</tr>
									
						{
							posts?.map((post, id) => 
								<tr className="content-row" key={id}>
									{selectedKey.map((key)=>{
										if(key==="userType")
										{
											return <td>
												{post?.userType[0].toUpperCase()+post?.userType?.slice(1)}
													
											</td>

										}
										else if(key==="createdAt"||key==="updatedAt")
										{
											return <td>{moment(post[key]).format("MMM DD, YYYY")}</td>
										}
										else if(key==="status")
										{
											return <td><label className={post.status===POSTS.STATUS.REJECTED?styles.rejected: post.status===POSTS.STATUS.PENDING && styles.pending}>
												{post.status[0].toUpperCase()+post.status.slice(1)}
											</label></td>
										}
										else{
											return <td>{post[key]}</td>
										}
									})}
									{/* <td><img src={cmlogo} alt="" /><b>Mosciski Inc.</b></td> */}
									{/* <td>{user.isVerified ? "Verified" : "Not Verified"}</td> */}
									{/* <td>
														<label className={!user.status&&styles.inactive}>{user.status ? "Active" : "Not Active"}</label>
														{console.log(user.status)}
													</td> */}
									<td>
										<Dropdown className={commonStyles.tddropdown}>
											<Dropdown.Toggle className={styles.dropdownIcon}>
												{/* <a className="me-2"></a> */}
                                                               
												<FontAwesomeIcon
													// className={commonStyles.iconmargin}
													icon={faEllipsisH}
												/>
											</Dropdown.Toggle>

											<Dropdown.Menu>
												<Dropdown.Item
													onClick={() => {
														// dispatch(editUser({ id: user._id }));
														// setShowEditModal(true);
														navigate(`${RoutesUrl.POSTS}/${post._id}`)
													}}
												>
													<FontAwesomeIcon
														size="xs"
														className={commonStyles.iconmargin}
														icon={faEye}
													/>
																View More
												</Dropdown.Item>

												<Dropdown.Item
													onClick={() => {
														dispatch(editPost({ id: post._id }));
														setChangeStatusModal(true);
													}}
												>
													<FontAwesomeIcon
														size="xs"
														className={commonStyles.iconmargin}
														icon={faCircleCheck}
													/>
																Change Status
												</Dropdown.Item>
																
											</Dropdown.Menu>
										</Dropdown>
									</td>
								</tr>
							) }					
									
					</table>
					{hasMore&&<div ref={elementRef}><div className= {`${styles.spinner} d-flex justify-content-center`}>
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div></div>}
							
								
				</div>
						
			</div>	
			<ChangeStatus
				showModal={showChangeStatusModal}
				handleClose={handleChangeStatusClose}
			/>
			<AddPost
				showModal={showAddPostModal}
				handleClose={handleAddPostClose}
			/>
			<SelectColumns showModal={showSelectModal}
				handleClose={handleSelectClose}
				selectedKeys={selectedKey}
				setSelectedKeys={setSelectedKey}
			/>
		</Layout>
				
	);
}

export default AllAdvertisementPosts;
