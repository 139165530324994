import { axios } from "../../../lib/axios";
import { ApiRoutes } from "../../../utils/api_Routes";
import { TIMESHEETS } from "../../../utils/timesheets";

export const getAll = (paginationPayload, selectedKeys, search, order) => {
  const params = {
    ...paginationPayload,
    selectedKeys,
    search,
    orderBy: "createdAt",
    order,
  };

  return axios.get(ApiRoutes.EXPENSE.getAll, { params: params });
};

export const getSpecificTimesheet = (id) => {
  return axios.get(`${ApiRoutes.EXPENSE.getAll}/${id}`);
};
export const changeStatus = (status, id) => {
  return axios.put(`${ApiRoutes.EXPENSE.getAll}/${id}`, { status: status });
};
export const allTimesheet = (status, userId, workId, paginationPayload, selectedKey) => {
  const statusPayload = {};
  if (status !== TIMESHEETS.STATUS.ALL) {
    statusPayload.status = status;
  }
  return axios.get(`${ApiRoutes.EXPENSE.SPECIFIC_EXPENSE}`, {
    params: { userId, workId, ...paginationPayload, ...statusPayload, selectedKey},
  });
};

export const getExpenseReport = () => {
    return axios.get(`${ApiRoutes.INVOICE.GET_INVOICE}?isBusiness=${true}`);
}; 
