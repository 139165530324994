import React, { useState } from "react"
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";

import styles from  "../style.module.css"
import commonstyles from "../../../../../common/common.module.css"
import { messages } from "../../../../../utils/messages";
import { AddPlanSchema } from "../validations";
import { addSubscriptionPlan } from "../api";
import { SUBSCRIPTIONS } from "../../../../../utils/subscriptions";
const AddSubscriptionPlan = ({ showModal, handleClose}) => {
	const [uploading, setUploading] = useState(false);
	const addSubscriptionPlanFormik = useFormik({
		validationSchema:AddPlanSchema,
		initialValues: {
			name: "",
			price:""
            
		},

		onSubmit: (values) => {
			
			// const payload={link:values.link,  files, description:values.description}
			toast.promise(
				addSubscriptionPlan(values),
				{
					pending: {
						render() {
							return messages.MASTER.ADDING
						},
					},
					success: {
						render() {
							addSubscriptionPlanFormik.resetForm();
							handleClose();
							setUploading(false);
							return messages.MASTER.ADDED
						},
					},
					error: {
						render() {
							setUploading(false);
							return messages.MASTER.FAILED
						},
					},
				}
			);
			
		
		},
	});
	const handleAddPost=(e)=>{
		e.preventDefault();
		addSubscriptionPlanFormik.submitForm();

	}

	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
				addSubscriptionPlanFormik.resetForm();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>
                Add Subscription Plan
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleAddPost}>
				<Modal.Body>
				
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="name"
									value={addSubscriptionPlanFormik.values.name}
									name={SUBSCRIPTIONS.FORMIK.NAME}
									onChange={addSubscriptionPlanFormik.handleChange}
								/>
							</Form.Group>
							{addSubscriptionPlanFormik.errors.name &&
                        addSubscriptionPlanFormik.touched.name ? (
									<span className={commonstyles.error}>
										{ addSubscriptionPlanFormik.errors.name}
									</span>
								) : null}
						</div>	
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>Price</Form.Label>
								<Form.Control
									type="number"
									placeholder="price"
									value={addSubscriptionPlanFormik.values.price}
									name={SUBSCRIPTIONS.FORMIK.PRICE}
									onChange={addSubscriptionPlanFormik.handleChange}
								/>
							</Form.Group>
							{addSubscriptionPlanFormik.errors.price &&
                        addSubscriptionPlanFormik.touched.price ? (
									<span className={commonstyles.error}>
										{ addSubscriptionPlanFormik.errors.price}
									</span>
								) : null}
						</div>	
								
					
				
				</Modal.Body>
				<Modal.Footer>
					<Button className={`${styles.buttonss} mt-3`} type="submit" disabled={uploading}>
                    	Add
					</Button>
				</Modal.Footer>
				</Form>
			</div>
		</Modal>
	)
}

export default AddSubscriptionPlan