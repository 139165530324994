import React, { useEffect, useRef, useState } from "react";
// import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import searchi from "./../../assets/images/search.png"
import {
	faEdit,
	faEllipsisH,
	faTableColumns,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";

import styles from "./style.module.css";
// import Sidebar from "../../common/Sidebar";
// import Topbar from "../../common/Topbar";
import upload from "../../assets/images/upload.png";
// import email from "../../assets/images/email.png";
// import phone from "../../assets/images/phone.png";
// import cmlogo from "../../assets/images/cmlogo.png";
// import dots from "../../assets/images/dots.png";
// import payone from "../../assets/images/payone.png"
// import paytwo from "../../assets/images/paytwo.png"
// import paythree from "../../assets/images/paythree.png"
// import info from "../../assets/images/info.png";
// import grarow from "../../assets/images/grarow.png";
// import blarow from "../../assets/images/blarow.png";
import { getInquiriesByType } from "./api";
import { WEBSITE_INQUIRIES } from "../../utils/website_inquiries";
import { editInquiry } from "../../redux/slices/inquirySlice";
import ViewInquiry from "./modals/ViewInquiry";
import commonStyles from "../../common/common.module.css";
import RemoveInquiry from "./modals/RemoveInquiry";
// import ChangePassword from "./modals/ChangePassword";
import { useInfiniteScroll } from "../../common/infiniteScroll/useInfiniteScroll";
import { Layout } from "../../common/Layout";
import { downloadExcel } from "../../common/api";
import { ApiRoutes } from "../../utils/api_Routes";
import { Form } from "react-bootstrap";
import SelectColumns from "./modals/SelectColumns";

function WebsiteInquiries() {
	const dispatch = useDispatch();
	const [inquiries, setInquiries] = useState([]);
	const [tab, setTab] = useState(WEBSITE_INQUIRIES.TYPE.ALL);
	const [activeTab, setActiveTab] = useState(WEBSITE_INQUIRIES.TYPE.ALL);
	const [showViewModal, setShowViewModal] = useState(false);
	const [order, setOrder]=useState(-1)

	const [showSelectModal, setShowSelectModal] = useState(false);
	const [selectedKey, setSelectedKey]=useState([ "userName",
		"userEmail", "createdAt", "status"
	])
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(1);
	const [loading, setLoading]=useState(true);
	const [search, setSearch]=useState("");
	const elementRef = useRef(null);

	const handleExcelDownload = () => {
		downloadExcel(ApiRoutes.WEBSITE_INQUIRIES.excel)
	}
	const handleSelectClose=()=>{
		setShowSelectModal(false)
	}

	const fetchMore = () => {
		setLoading(true);
		getInquiriesByType(tab, { page, limit: 10 }, search, selectedKey).then((res) => {
			setLoading(false);
			const { data } = res;
			if (data.length === 0) {
				setHasMore(false);
			} else {
				if (page === 1) {
					setInquiries(data);
				} else {
					setInquiries((prev) => [...prev, ...data]);
				}
				setPage((prev) => prev + 1);
			}
			setActiveTab(tab);
		});
	};

	useInfiniteScroll(inquiries, elementRef, hasMore, fetchMore);

	const handleViewClose = () => {
		setShowViewModal(false);
	};
	const handleRemoveClose = () => {
		setShowRemoveModal(false);
	};

	useEffect(() => {
		setPage(1);
		setHasMore(true);

		setInquiries([]);
	}, [tab]);

	useEffect(() => {
		if (showViewModal === false) {
			setPage(1);
			setHasMore(true);

			setInquiries([]);
		}
	}, [showViewModal]);
	useEffect(() => {
		if (showSelectModal === false) {
			setPage(1);
			setHasMore(true);

			setInquiries([]);
		}
	}, [showSelectModal]);
	useEffect(() => {
		if (showRemoveModal === false) {
			setPage(1);
			setHasMore(true);

			setInquiries([]);
		}
	}, [showRemoveModal]);
	const handleActivePanel = (type) => {
		setTab(type);
	};
	const handleSearchChange=(e)=>{
		setSearch(e.target.value)
	}
	const handleReset=()=>{
		setSearch("")
		setPage(1);
		setHasMore(true);

		setInquiries([])

	}
	const handleSearch=()=>{

		setPage(1);
		setHasMore(true);

		setInquiries([])
	}

	const handleOrder=(value)=>{
		setOrder(Number(value))
		handleSearch()

	}

	return (
		<Layout>
			<h2>Website Inquiries</h2>
			{/* <div className={styles.OuterTime}>
				<div className="row">
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className={styles.revenueBox}>
									<div className={styles.revenueBoxHead}>
										<h6>
											Total Revenue <img src={info} alt="" />{" "}
										</h6>
										<span>
											<img src={grarow} alt="" /> 30%
										</span>
									</div>
									<h5>$250,520</h5>
									<div className={styles.revenueBoxFoter}>
										<h4>
											+$30,215 <span>from last month</span>
										</h4>
										<span>
											More <img src={blarow} alt="" />
										</span>
									</div>
								</div>
							</div>
						</div> 
			</div>*/}
			<div className={styles.tableTime}>
				<div className={styles.topTime}>
					<h5>
            Website Inquiries <i className="fas fa-info-circle"></i>
					</h5>
					<div className={styles.topTimeInner}>
						<form className={styles.formGroup} 
							onSubmit={(e)=>{
								e.preventDefault();
								handleSearch()}
							}>
							<input value={search} onChange={handleSearchChange} disabled={loading}  type='text' placeholder='Search'/>
							<button onClick={handleSearch} className={styles.SaerchTag} disabled={loading}><span><img src={searchi} alt=""/></span></button>
							<button disabled={loading} className={styles.resetButton} onClick={handleReset}  ><span>Reset</span></button>
							
						</form>
						<button onClick={handleExcelDownload} className={styles.exportButton}>
							<img src={upload} alt="" /> Export
						</button>
						<button onClick={()=>setShowSelectModal(true)} className={styles.selectButton}>
							<FontAwesomeIcon
								// className={commonStyles.iconmargin}
								icon={faTableColumns}
							/>
							<span>Select Columns</span>
						</button>
					</div>
				</div>
				<div className={`${styles.extrafilter} ${styles.topTime}`}>
					
					<div className={styles.FilterBtn}>
						<Form.Group className="from-group" controlId="type">
							<Form.Label>Status:</Form.Label>
							<Form.Control
										
								value={activeTab}
								as="select"
								onChange={(e)=>handleActivePanel(e.target.value)}
	
										
							>
								{
									["All", "answered", "unanswered", "rejected"].map((item)=><option value={item} key={1}>
										{item}
									</option>)
								}
								
									
							</Form.Control>
						</Form.Group>

						<Form.Group className="from-group" controlId="type">
							<Form.Label>Order By Date:</Form.Label>
							<Form.Control
										
								value={order}
								as="select"
								onChange={(e)=>handleOrder(e.target.value)}
	
										
							>
								
								<option value={1} key={1}>
						Ascending
								</option>
								<option value={-1} key={2}>
						Descending
								</option>
								
									
							</Form.Control>
						</Form.Group>
					
					</div>
				</div>
				<div className={styles.Tabstable}>
					<ul>
						
						{/* <li
							className={
								activeTab === WEBSITE_INQUIRIES.TYPE.ALL && styles.active
							}
						>
							<Link
								onClick={() => handleActivePanel(WEBSITE_INQUIRIES.TYPE.ALL)}
							>
                All Inquiries
							</Link>
						</li>

						<li
							className={
								activeTab === WEBSITE_INQUIRIES.TYPE.ANSWERED && styles.active
							}
						>
							<Link
								onClick={() =>
									handleActivePanel(WEBSITE_INQUIRIES.TYPE.ANSWERED)
								}
							>
                Answered
							</Link>
						</li>
						<li
							className={
								activeTab === WEBSITE_INQUIRIES.TYPE.UNANSWERED && styles.active
							}
						>
							<Link
								onClick={() =>
									handleActivePanel(WEBSITE_INQUIRIES.TYPE.UNANSWERED)
								}
							>
                Unanswered
							</Link>
						</li> */}
					</ul>
					{/* <div className={styles.filters}>
								<div className={styles.Formgroup}>
									<label>Arrange : </label>
									<select>
										<option>Columns</option>
										<option>Columns</option>
									</select>
								</div>
								<div className={styles.Formgroup}>
									<label>Select : </label>
									<select>
										<option>Columns</option>
										<option>Columns</option>
									</select>
								</div>
							</div> */}
				</div>
				<div className={styles.Tbalelayout}>
					<table>
						<tr>
							{/* <th>Name /Profile</th>
							<th>Email</th>
							<th>Created On</th>
							<th>Status</th> */}
							{selectedKey.map((key, ind)=>{
								return <td key={ind}>{key}</td>
							})}
							<th>Action</th>
						</tr>
						{inquiries?.map((inquiry, id) => (
							<tr key={id}>
								{
									selectedKey.map((key, ind)=>{
										return <td key={ind}>{inquiry[key]}</td>
									})
								}
								{/* <td>
									<img src={tabpro} alt="" /> {inquiry?.user?.name}
								</td>
								<td>{inquiry.user.email}</td>
								<td>{moment(inquiry.createdAt).format("MMM DD, YYYY")}</td>
								<td>
									<label
										className={
											inquiry.status === WEBSITE_INQUIRIES.TYPE.UNANSWERED &&
                      styles.inactive
										}
									>
										{inquiry.status === WEBSITE_INQUIRIES.TYPE.UNANSWERED
											? "Unanswered"
											: "Answered"}
									</label>
								</td> */}
								<td>
									<Dropdown className={commonStyles.tddropdown}>
										<Dropdown.Toggle className={styles.dropdownIcon}>
											{/* <a className="me-2"></a> */}
											<FontAwesomeIcon
												// className={commonStyles.iconmargin}
												icon={faEllipsisH}
											/>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											<Dropdown.Item
												onClick={() => {
													dispatch(editInquiry({ id: inquiry._id }));
													setShowViewModal(true);
												}}
											>
												<FontAwesomeIcon
													size="xs"
													className={commonStyles.iconmargin}
													icon={faEdit}
												/>
                        View And Reply
											</Dropdown.Item>

											<Dropdown.Item
												onClick={() => {
													dispatch(editInquiry({ id: inquiry._id }));
													setShowRemoveModal(true);
												}}
											>
												<FontAwesomeIcon
													size="xs"
													className={commonStyles.iconmargin}
													icon={faTrash}
												/>
                        Remove
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</td>
							</tr>
						))}
					</table>
					{hasMore && (
						<div ref={elementRef}>
							<div
								className={`${styles.spinner} d-flex justify-content-center`}
							>
								<div className="spinner-border" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<ViewInquiry showModal={showViewModal} handleClose={handleViewClose} />
			<RemoveInquiry
				showModal={showRemoveModal}
				handleClose={handleRemoveClose}
			/>
			<SelectColumns showModal={showSelectModal}
				handleClose={handleSelectClose}
				selectedKeys={selectedKey}
				setSelectedKeys={setSelectedKey}
			/>
		</Layout>
	);
}

export default WebsiteInquiries;
