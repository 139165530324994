import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import styles from "../style.module.css";
import { changeInvoiceStatus } from "../api";
import { messages } from "../../../utils/messages";
import { MASTER } from "../../../utils/master";
import { RoutesUrl } from "../../../utils/RoutesUrl";

const ChangeStatus = ({ showModal, handleClose, status, id }) => {
  // const { id } = useParams();
  const navigate = useNavigate();
  console.log(id, "id");
  

  const handleChangeStatus = (newStatus) => {
    toast.promise(changeInvoiceStatus(id, newStatus), {
      pending: {
        render() {
          return messages.MASTER.CHANGE_STATUS;
        },
      },
      success: {
        render() {
          navigate(RoutesUrl.TIMESHEETS);

          return messages.MASTER.CHANGED_STATUS;
        },
      },
      error: {
        render() {
          return messages.MASTER.CHANGE_STATUS_FAILED;
        },
      },
    });
  };

  return (
    <Modal show={showModal} onHide={() => {handleClose();}} centered backdrop='static'>
      <div className={styles["add-lead-from"]}>
        <Modal.Header closeButton>
          <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {
              <h5 className="text-center">
                Do you want to{" "}
                {status === MASTER.STATUS.REJECTED ? "reject" : "approved"} the
                Invoice?
              </h5>
            }
            <Form>
              <div
                className={`justify-content-center gap-3 ${styles.spacebetween}`}
              >
                <Button
                  onClick={() => handleChangeStatus(MASTER.STATUS.APPROVED)}
                  className={`${styles.statusTrue} mt-3`}
                >
                  Approve
                </Button>
                <Button
                  onClick={() => {handleClose();}}
                  className={`${styles.statusFalse} mt-3`}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ChangeStatus;
