import React from "react"
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import styles from "../style.module.css";
import commonstyles from "../../../common/common.module.css";
import { messages } from "../../../utils/messages";
import { AddTypeSchema } from "../validations";
import { addType } from "../api";
import { MASTER } from "../../../utils/master";

const AddType = ({ showModal, handleClose, type }) => {

	const addTypeFormik = useFormik({
		initialValues: {
			name: "",
		},
		validationSchema: AddTypeSchema,
		onSubmit: (values) => {
			toast.promise(
				addType({...values, type}),
				{
					pending: {
						render() {
							return messages.MASTER.ADDING
						},
					},
					success: {
						render() {
							addTypeFormik.resetForm();
							handleClose();

							return messages.MASTER.ADDED
						},
					},
					error: {
						render() {
							return messages.MASTER.FAILED
						},
					},
				}
			);
		
		
		},
	});
	const handleAddType=(e)=>{
		e.preventDefault();
		addTypeFormik.submitForm();

	}
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
				addTypeFormik.resetForm();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>
						Add {type === MASTER.TYPE.SOFTWARE_LICENCES
							? "Software Licences"
							: type.charAt(0).toUpperCase() + type.slice(1)}
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleAddType}>
				<Modal.Body>
					
						<div className={styles.fieldStyle}>
							<Form.Group className="form-group" controlId="formName">
								<Form.Label>Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="Name"
									value={addTypeFormik.values.name}
									name={MASTER.FORMIK.NAME}
									onChange={addTypeFormik.handleChange}
								/>
							</Form.Group>
							{addTypeFormik.errors.name &&
                                addTypeFormik.touched.name ? (
									<span className={commonstyles.error}>
										{addTypeFormik.errors.name}
									</span>
								) : null}
						</div>		
					
					
				</Modal.Body>
				<Modal.Footer>
				<Button className={`${styles.buttonss} mt-3`} type="submit">
							Add
						</Button>
				</Modal.Footer>
				</Form>
			</div>
		</Modal>
	)
}

export default AddType