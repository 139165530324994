import { axios } from "../../../lib/axios";
import { ApiRoutes } from "../../../utils/api_Routes";

export const fetchUserChatLists = async (filter = "all",searchValue = "") => {
    try {
      const response = await axios.get(`${ApiRoutes.CHAT.USER_CHAT}?limit=10&page=1&type=${filter}&search=${""}`);
  
      return response.data; // Directly return the array of users
    } catch (error) {
      console.error('Error fetching chat users:', error);
      throw error; // Handle or rethrow the error as needed
    }
  };


  export const getUserProfileById = async (id, type)=> {
    if (type) {
      return axios.get(`${ApiRoutes.PROFILE.GET_USERPROFILE_BY_ID}/${id}?type=${type}`);
    } else {
      return axios.get(`${ApiRoutes.PROFILE.GET_USERPROFILE_BY_ID}/${id}`);
  
    }
}


export const blockUser = async (id)=> {
  
      return axios.post(`${ApiRoutes.CHAT.BLOCK_CHAT_USER}`,{blockedId:id});
   
}

export const reportUser = async (id)=> {
  
    return axios.post(`${ApiRoutes.CHAT.REPORT_USER}`,{reportedId: id,reason:"this user is spam"});
 
}