import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import styles from "../style.module.css";
import { changeStatus, getSpecifiedPost } from "../api";
import { messages } from "../../../utils/messages";
import { MASTER } from "../../../utils/master";

const ChangeStatus = ({ showModal, handleClose }) => {
	const postId = useSelector((state) => state.posts.id);
	const [loading, setLoading] = useState(true);
	const [currentPost, setCurrentPost]=useState()
	useEffect(() => {
	 setLoading(true);
	 if(postId&&postId!=="")
	 getSpecifiedPost(postId).then((res)=>{
				setCurrentPost(res.data);
				setLoading(false)
	 })

	}, [showModal])
	

	const handleChangeStatus = (newStatus) => {
		toast.promise(changeStatus(newStatus, postId), {
			pending: {
				render() {
					return messages.MASTER.CHANGE_STATUS;
				},
			},
			success: {
				render() {
					handleClose();

					return messages.MASTER.CHANGED_STATUS;
				},
			},
			error: {
				render() {
					return messages.MASTER.CHANGE_STATUS_FAILED;
				},
			},
		});
	};
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>Change Status</Modal.Title>
				</Modal.Header>
				<Form>
					<Modal.Body>
						{!loading?<>
							{currentPost?.status!==MASTER.STATUS.PENDING&&<h5>Do you want to {currentPost?.status===MASTER.STATUS.APPROVED?"Disable":"Enable"} it?</h5>}
							
								<div className={`justify-content-center gap-3 ${styles.spacebetween}`}>
									{currentPost?.status===MASTER.STATUS.PENDING&&<Button
										onClick={()=>handleChangeStatus(MASTER.STATUS.APPROVED)}
										className={`${styles.statusTrue} mt-3`}
									>
					Approve
									</Button>}
									{currentPost?.status===MASTER.STATUS.PENDING&&<Button
										onClick={()=>handleChangeStatus(MASTER.STATUS.REJECTED)}							
										className={`${styles.statusFalse} mt-3`}
									>
					Reject
									</Button>}
									{currentPost?.status!==MASTER.STATUS.PENDING&&<Button
										onClick={()=>handleChangeStatus(currentPost?.status===MASTER.STATUS.APPROVED?MASTER.STATUS.REJECTED:MASTER.STATUS.APPROVED)}
										className={`${styles.statusTrue} mt-3`}
									>
					Yes
									</Button>}
									{currentPost?.status!==MASTER.STATUS.PENDING&&<Button
										onClick={handleClose}
									
										className={`${styles.statusFalse} mt-3`}
									>
					No
									</Button>}
								</div>
						</>:(
							<div className="d-flex justify-content-center">
								<div className="spinner-border" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							</div>
						)}
					</Modal.Body>
				</Form>
			</div>
		</Modal>
	);
};

export default ChangeStatus;
